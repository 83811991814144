import { Box, Button, CardMedia, DialogActions, DialogContent, DialogTitle, Modal, Stack, styled, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import toaster from "../../components/Toaster/toaster";
import useLoading from "../../hooks/useLoading";
import React, { useState } from "react";
import {
  useUpdateUserProfileMutation,
  useUploadImageMutation,
} from "../../services/apiService/userApiService";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../store/Slicers/authSlice";
import { useGetInstagramDataByFieldsMutation, useGetInstagramMediaByFbMutation, useGetInstagramProfileByFbMutation } from "../../services/apiService/socialMediaApiService";

const UploadProfile = ({ setStep }) => {
  const dispatch = useDispatch();
  const [imageUpload] = useUploadImageMutation();
  const [updateProfileImage] = useUpdateUserProfileMutation();
  const [fetchInstagramImage] = useGetInstagramDataByFieldsMutation();
  const [getInstagramProfileByFb] = useGetInstagramProfileByFbMutation()
  const [getInstagramMediaByFb] = useGetInstagramMediaByFbMutation()
  const { userDtls, instagramData } = useSelector((state) => state?.auth);
  const [openImageModal, setOpenImageModal] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const [uploadFile, setUploadFile] = useState(null);
  const [imageList, setImageList] = useState([]);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  /**
   * To fetch instagram image
   */
  const getInstagramImageList = async () => {
    try {
      startLoading();
      console.log(instagramData)
      const profileDtls = await getInstagramProfileByFb({
        id: instagramData.user_id,
        fields: "profile_picture_url",
        access_token: instagramData?.access_token,
      }).unwrap();
      let imageList = [];
      if (profileDtls?.profile_picture_url) {
        imageList.push({
          id: profileDtls?.id,
          media_url: profileDtls?.profile_picture_url,
        });
      }
      const response = await getInstagramMediaByFb({
        id: instagramData.user_id,
        fields: "id,media_type,media_url,permalink",
        access_token: instagramData?.access_token,
      }).unwrap();
      if (response?.data) {
        const formateData = response?.data.filter(
          (ele) => ele?.media_type === "IMAGE"
        );
        setImageList([...formateData, ...imageList]);
      } else {
        setImageList([]);
        toaster("error", "Fetch instagram image failed");
      }
    } catch (err) {
      console.error("Fetch instagram image failed", err);
      toaster("error", "Something went wrong");
    } finally {
      stopLoading();
    }
  };
  /**
   * To upload the profile image
   */
  const handlerUploadImage = async () => {
    try {
      startLoading();
      let imageUrl = {}
      if (typeof uploadFile !== "string") {
        const formData = new FormData();
        formData?.append("files", uploadFile);
        imageUrl = await imageUpload(formData)?.unwrap();
      } else {
        imageUrl = { data: [uploadFile] }
      }
      if (imageUrl?.data?.length > 0) {
        const response = await updateProfileImage({
          userId: userDtls?.userId,
          imageId: imageUrl?.data[0],
        }).unwrap();
        if (response?.data) {
          dispatch(setUserDetails(response?.data));
          setStep("accountConfirm");
        } else {
          toaster("error", "Reset password failed");
        }
      } else {
        toaster("error", imageUrl?.message);
        return;
      }
    } catch (err) {
      console.error("Failed to Reset password:", err);
      toaster("error", "Something went wrong");
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle the upload image from instagram
   */
  const handlerUploadFromInstagram = async () => {
    await getInstagramImageList();
    setOpenImageModal(true);
  };
  return (
    <>
      <Box
        height={" calc(100% - 240px)"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          variant="h5"
          padding={1}
          sx={{ height: "63px" }}
          fontWeight={500}
          fontSize={16}
          color={"primary"}
          mt={8}
        >
          Upload you profile
        </Typography>
        {uploadFile && (
          <Box width={200} height={200}>
            <img
              style={{
                display: "block",
                height: "100%",
                margin: "auto",
                width: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={typeof uploadFile == "string" ? uploadFile : URL.createObjectURL(uploadFile)}
              alt="loading"
            ></img>
          </Box>
        )}
        <Stack gap={2}>
          <Button
            sx={{ textTransform: "capitalize", m: "auto", width: "100%" }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            color={"secondary"}
            startIcon={<AddOutlinedIcon sx={{ mr: "auto" }} />}
          >
            Upload From local
            <VisuallyHiddenInput
              type="file"
              accept=".jpg, .jpeg, .png,.webp"
              onChange={(e) => {
                setUploadFile(e?.target?.files[0]);
              }}
            // multiple
            />
          </Button>
          <Button
            sx={{ textTransform: "capitalize", m: "auto" }}
            // component="label"
            // role={undefined}
            variant="contained"
            tabIndex={-1}
            color={"secondary"}
            startIcon={<AddOutlinedIcon />}
            onClick={handlerUploadFromInstagram}
          >
            Upload from instagram
          </Button>
        </Stack>
        <Stack
          marginTop={"auto"}
          direction={"row"}
          gap={2}
          width={"60%"}
          justifyContent={"center"}
        >
          <>
            <Button
              variant="contained"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "capitalize",
                color: "primary.main",
                height: 56,
                width: 162,
                borderRadius: 4,
                bgcolor: "white.main",
              }}
              size="large"
              onClick={() => setStep("signUp")}
            >
              Back
            </Button>
            <Button
              disabled={!uploadFile}
              variant="contained"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "capitalize",
                height: 56,
                width: 162,
                borderRadius: 4,
              }}
              size="large"
              onClick={() => {
                handlerUploadImage();
              }}
            >
              Next
            </Button>
          </>
        </Stack>
      </Box>
      <Modal
        className="flex"
        open={openImageModal}
        onClose={() => setOpenImageModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {/* <div className="profileImageCtn"> */}
        <Box
          minWidth={340}
          width={340}
          height={374}
          sx={{ bgcolor: "#ece6f0", borderRadius: "20px" }}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          position={"relative"}
        >
          <DialogTitle
            id="responsive-dialog-title"
            textAlign={"center"}
            fontSize={24}
            fontWeight={400}
          >
            Select you profile
          </DialogTitle>
          <DialogContent sx={{ width: 300, p: 0 }}>
            <Box display={"flex"} flexWrap={"wrap"} gap={1}>
              {imageList?.map((ele) => {
                return (
                  <CardMedia key={ele?.id}
                    onClick={(e) => {
                      if (ele?.media_url === uploadFile) {
                        setUploadFile({});
                      } else {
                        setUploadFile(ele?.media_url);
                      }
                    }}
                    className={
                      uploadFile === ele?.media_url ? "selectedImage" : ""
                    }
                    component="img"
                    alt="green iguana"
                    height={"94px"}
                    width={"94px"}
                    image={ele?.media_url}
                    sx={{
                      width: "94px",
                      borderRadius: "5px",
                      minWidth: "94px",
                    }} />
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions sx={{ mt: "auto" }}>
            <Button
              variant="contained"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "capitalize",
                color: "primary.main",
                height: 56,
                width: 140,
                borderRadius: 4,
                bgcolor: "white.main",
              }}
              size="large"
              onClick={() => {
                setUploadFile({})
                setOpenImageModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                setOpenImageModal(false)
              }}
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "capitalize",
                color: "white.main",
                height: 56,
                width: 140,
                borderRadius: 4,
                // bgcolor: "white.main",
              }}
              variant="contained"
              disabled={!uploadFile}
            >
              Update
            </Button></DialogActions>
        </Box>
        {/* </div> */}
      </Modal>
    </>
  );
};
export default UploadProfile;
