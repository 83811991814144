import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Chip,
  TextField,
  IconButton,
  Modal,
  Typography,
  Button,
  SvgIcon,
  DialogTitle,
  Dialog,
} from "@mui/material";
import MyButton from "../../MyButton";
import ListIcon from "@mui/icons-material/List";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import Autocomplete from "@mui/material/Autocomplete";
import TuneIcon from "@mui/icons-material/Tune";
import SearchBar from "../../Search";
import CloseIcon from "@mui/icons-material/Close";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./InfluencerOffers.css";
import InfluencerOffersList from "./InfluencerOffersList";
import PhotoView from "./PhotoView";
import defaultProfileImg from "../../../assets/image/defaultProfileImg.png";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import LabelRoundedIcon from "@mui/icons-material/LabelRounded";
import {
  useGetCompanyOrInfluencerListMutation,
  useGetOffersMutation,
  useGetWishListMutation,
  useSaveOfferMutation,
  useUpdateBookingStatusMutation,
} from "../../../services/apiService/userApiService";
import { useDispatch, useSelector } from "react-redux";
import toaster from "../../Toaster/toaster";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
// import bookmarkIcon from "../../../assets/Icons/bookmarkIcon.svg";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  categoryList,
  filterDtlsInitialValue,
  notificationCode,
  offerStatusCode,
} from "../../../utils/constants";
import useNotification from "../../../hooks/usePushNotification";
import useLoading from "../../../hooks/useLoading";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import LocationAutoComplete from "../../LocationAutoComplete/LocationAutoComplete";
import { FixedSizeList as List } from "react-window";
import materialIcons, {
  AddIcon,
  BookMarkIcon,
  CalenderIcon,
  GridIcon,
  MySearchIcon,
  OfferIcon,
  RemoveIcon,
  SavedBookMarkIcon,
} from "../../../utils/materialIcon";
import CheckIcon from "@mui/icons-material/Check";
import { formatDate } from "../../../utils/common";
import completedIcon from "../../../assets/Icons/completedIcon.png";
import { setInfluencerOfferFilter } from "../../../store/Slicers/filterSlice";
const InfluencerOffers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getOffers] = useGetOffersMutation();
  const { sendNotification } = useNotification();
  const [updateBookingStatus] = useUpdateBookingStatusMutation();
  const [getInfluencerOrCompanyList] = useGetCompanyOrInfluencerListMutation();
  const [getWishList] = useGetWishListMutation();
  const [saveOffer] = useSaveOfferMutation();
  const { startLoading, stopLoading } = useLoading();
  const { userDtls, isCompany, instagramData } = useSelector(
    (state) => state?.auth
  );
  const { influencerOfferFilter } = useSelector((state) => state?.filter);
  const [offersList, setOffersList] = useState([]);
  const [location, setLocation] = useState({});
  const [filterLocation, setFilterLocation] = useState({});
  const [nameList, setNameList] = useState([]);
  const [filterDtls, setFilterDtls] = useState(filterDtlsInitialValue);
  const [query, setQuery] = useState("");
  const [categories, setCategories] = useState(categoryList);
  const [dateList, setDateList] = useState({
    fromDate: { start: "", end: "" },
    toDate: { start: "", end: "" },
  });
  const [pageView, setPageView] = useState("list");
  const [open, setOpen] = useState(false);
  const [acceptOfferDialog, setAcceptOfferDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  /**
   * To handler fetch offers list
   */
  const handlerFetchOffers = async (noFilter, query, isExpiring) => {
    try {
      startLoading();
      let filter = {};
      if (noFilter) {
        filter.status = "active";
        if (isExpiring) filter.isExpiring = true;
      } else {
        filter = { ...filterDtls };
        if (dateList?.fromDate?.start || dateList?.fromDate?.start) {
          filter.fromDate = dateList?.fromDate;
        }
        if (dateList?.toDate?.start || dateList?.toDate?.start) {
          filter.toDate = dateList?.toDate;
        }
        filter.isCompany = userDtls.type === "company" ? true : false;
        filter.categories = filterDtls?.categories?.map((ele) =>
          ele?.value.replace("&", "-")
        );
        if (location?.locationName?.length > 0) {
          filter.location = filterLocation;
        }
        if (isCompany) {
          filter.influencerId = filterDtls?.name?.userId;
        } else {
          filter.businessId = filterDtls?.name?.userId;
        }
        filter = Object.fromEntries(
          Object.entries(filter).filter(
            ([key, value]) =>
              value !== null &&
              value !== undefined &&
              value !== "" &&
              !(Array.isArray(value) && value.length === 0)
          )
        );
      }
      if (query?.length > 0) filter.search = query;
      if (isExpiring || influencerOfferFilter === "isExpiring")
        filter.isExpiring = true;
      if (userDtls?.type === "influencer") {
        filter.influencerId = userDtls?.userId;
      }
      filter.followerCount = instagramData?.followers_count || 0;
      const response = await getOffers({
        id: userDtls?.userId,
        filter,
      }).unwrap();
      if (response?.data) {
        offersListFormatter(response?.data);
      } else {
        setOffersList([]);
      }
    } catch (err) {
      setOffersList([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To fetch company or influencer dtls
   */
  const fetchCompanyOrInfluencerDtls = async () => {
    try {
      startLoading();
      const resp = await getInfluencerOrCompanyList(
        isCompany ? "influencer" : "company"
      );
      if (resp?.data?.data?.length) {
        setNameList(resp?.data?.data || []);
      } else {
        setNameList([]);
      }
    } catch (err) {
      setNameList([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to  fetch name list:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    handlerFetchOffers(true, "", influencerOfferFilter === "expired");
    fetchCompanyOrInfluencerDtls();
  }, [userDtls]);

  const offersListFormatter = (data) => {
    const formattedData = data?.map((item) => {
      let ele = { ...item };
      if (ele?.isRequestToBusiness) {
        ele.offerStatus = "pending";
      } else if (ele?.isOfferedToInfluencer) {
        ele.offerStatus = "check";
      } else if (ele?.isCompletedByInfluencer) {
        ele.offerStatus = "complete";
      } else if (ele?.isCancelledByInfluencer) {
        ele.offerStatus = "cancelled";
      } else {
        ele.offerStatus = "accept";
      }
      return ele;
    });
    // const arr = Array.from({ length: 20 }).fill(formattedData[0]);
    setOffersList(formattedData);
  };
  /**
   * To update booking status
   */
  const handlerUpdateBookingStatus = async (e, data, links, postDetails) => {
    try {
      startLoading();
      let payload = {
        offerId: data?._id,
        influencerId: userDtls?.userId,
      };
      const notificationPayload = { isRead: false, type: "remainder" };
      if (data?.offerStatus === "accept") {
        payload.newStatus = "requestToBusiness";
        notificationPayload.message = notificationCode?.offerRequested;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          data?.createdBy[0]?.userId || data?._id;
      } else if (data?.offerStatus === "approved") {
        payload.newStatus = "offeredToInfluencer";
        notificationPayload.message = notificationCode?.offerAccepted;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          data?.createdBy[0]?.userId || data?._id;
      } else {
        payload.newStatus = "completedByInfluencer";
        notificationPayload.message = notificationCode?.offerCompleted;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          data.createdBy[0].userId || data.createdBy;
        payload.links = links;
        if (postDetails) payload.postDetails = postDetails;
      }
      const response = await updateBookingStatus(payload).unwrap();
      if (response?.data) {
        handlerFetchOffers(true);
        sendNotification(notificationPayload);
      } else {
        toaster("error", response?.data?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  const handlerAcceptOffer = async () => {
    try {
      startLoading();
      let payload = {
        offerId: selectedData?._id,
        influencerId: userDtls?.userId,
      };
      const notificationPayload = { isRead: false, type: "remainder" };
      if (selectedData?.offerStatus === "accept") {
        payload.newStatus = "requestToBusiness";
        notificationPayload.message = notificationCode?.offerRequested;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          selectedData?.createdBy[0]?.userId || selectedData?._id;
      }
      const response = await updateBookingStatus(payload).unwrap();
      if (response?.data) {
        handlerFetchOffers(true);
        sendNotification(notificationPayload);
        setAcceptOfferDialog(false);
        toaster("info", "Offer accepted successfully");
      } else {
        toaster("error", response?.data?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To fetch the wish list
   */
  const fetchWishList = async (id) => {
    try {
      startLoading();
      const response = await getWishList(userDtls?.userId);
      if (response?.data) {
        offersListFormatter(
          response?.data?.data?.map((ele) => ele?.offer) || []
        );
      } else {
        setOffersList([]);
        toaster("error", response?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
      } else {
        console.error("Failed to fetch wish list:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle select offer card
   */
  const handleSelectOffersCard = (data) => {
    navigate(`/offers/${data?._id}`);
  };

  const handleClearFilters = () => {
    setFilterDtls({ ...filterDtlsInitialValue });
    setDateList({
      fromDate: { start: "", end: "" },
      toDate: { start: "", end: "" },
    });
    setLocation({});
    setFilterLocation({});
    handlerFetchOffers(true);
  };

  /**
   * To handle date onchange
   */
  const handlerDateOnchange = (key1, key2, value) => {
    setDateList((prev) => {
      return { ...prev, [key1]: { ...prev[key1], [key2]: value } };
    });
  };

  /**
   * To handle changes in input field
   */
  const handlerOnchange = (key1, value) => {
    if (key1 === "categories") {
      setCategories(
        categories?.map((ele) => {
          if (ele?.id === value?.id) {
            ele.isSelected = !ele.isSelected;
          }
          return ele;
        })
      );
    }
    if (key1 === "location") {
      setFilterDtls({ ...filterDtls, location: value });
      return;
    }
    setFilterDtls({
      ...filterDtls,
      [key1]: Array.isArray(filterDtls[key1])
        ? checkDuplicate(filterDtls[key1], value)
        : value?.value || value || "",
    });
  };

  const checkDuplicate = (array, obj) => {
    const index = array.findIndex((item) => item?.id === obj?.id);
    if (index !== -1) {
      return array.filter((item) => item?.id !== obj?.id);
    } else {
      return [...array, obj];
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * To handle the filler funtion
   */
  const handleFilter = (code) => {
    dispatch(setInfluencerOfferFilter(code));
    // setFilterCode(code);
    if (code === "new") {
      handlerFetchOffers(true);
    } else if (code === "saved") {
      fetchWishList();
    } else if (code === "expired") {
      handlerFetchOffers(true, "", true);
    }
  };

  /**
   * To handle the page view
   */
  const handlePageView = (code) => {
    setPageView(code);
  };

  /**
   * for date range
   */
  const handleSelectDateRange = (rangeType) => {
    const today = dayjs();
    let startDate = today;
    let endDate = today;

    switch (rangeType) {
      case "today":
        startDate = today;
        endDate = today;
        break;
      case "yesterday":
        startDate = today.subtract(1, "day");
        endDate = today.subtract(1, "day");
        break;
      case "lastWeek":
        startDate = today.subtract(7, "day");
        endDate = today;
        break;
      case "last15Days":
        startDate = today.subtract(15, "day");
        endDate = today;
        break;
      case "lastMonth":
        startDate = today.subtract(1, "month").startOf("month");
        endDate = today.subtract(1, "month").endOf("month");
        break;
      case "thisMonth":
        startDate = today.startOf("month");
        endDate = today.endOf("month");
        break;
      default:
        return;
    }

    handlerDateOnchange("toDate", "start", startDate);
    handlerDateOnchange("toDate", "end", endDate);
  };

  /**
   * for virtual scroll
   * */
  const InfluencerOffersListVirtual = ({ index, style, data }) => {
    const ele = data[index];
    return (
      <div style={style}>
        <InfluencerOffersList
          data={ele}
          style={{ overFlow: "auto" }}
          handleSelectOffersCard={handleSelectOffersCard}
          module={"offer"}
          handlerUpdateBookingStatus={handlerUpdateBookingStatus}
          handlerFetchOffers={handlerFetchOffers}
          isSavedOffer={
            influencerOfferFilter === "saved" || ele?.isOfferSaved || false
          }
          children={getChildren(ele)}
          className={"offerTitle"}
        />
      </div>
    );
  };

  /**
   * To handle the navigation of the company profile
   */
  const handlerCompanyProfile = (e, data) => {
    e?.preventDefault();
    e?.stopPropagation();
    navigate(`/profile/${data?.createdBy?.[0]?.userId}`);
  };

  /**
   * To handle the save offer function
   * @param {event}
   */
  const handlerSaveOffer = async (e, data) => {
    try {
      e?.stopPropagation();
      e?.preventDefault();
      const payload = {
        offer_id: data?._id,
        // newStatus: "offeredToInfluencer",
        user_id: userDtls?.userId,
      };
      const response = await saveOffer(payload).unwrap();
      if (response?.success) {
        handlerFetchOffers(true);
        toaster("info", "Offer successfully saved");
      } else {
        toaster("info", response?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to save offer:", err);
        toaster("error", "Something went wrong");
      }
    }
  };

  const getChildren = (data) => {
    return (
      <>
        <Typography
          display={"flex"}
          className={"textEllipsis mobile-WidthFitContent text-userName "}
          width={"140px"}
          height={32}
          color={"text.main"}
          fontWeight="500"
          fontSize={"12px"}
          variant="body1"
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={20}
          // bgcolor={"#f4f3f6"}
          onClick={(e) => handlerCompanyProfile(e, data)}
        >
          {` by ${data?.createdBy[0]?.username ||
            data?.companyDetails?.username ||
            "Company "
            }`}
        </Typography>

        <Typography
          className="hideOnMobile"
          display={"flex"}
          variant="body1"
          fontSize={14}
          fontWeight={700}
          color="text.secondary"
          height={"32px"}
          width={"89px"}
          alignItems={"center"}
          justifyContent={"center"}
          border={1}
          borderColor={"#CAC4D0"}
          borderRadius={20}
        >
          {`${formatDate(data?.availableDates?.from, "DD/MM")}-${formatDate(
            data?.availableDates?.to,
            "DD/MM"
          )}`}
        </Typography>
        <Typography
          className="influencerBooking"
          padding={"6px 12px"}
          display={"flex"}
          variant="body1"
          fontSize={14}
          fontWeight={500}
          color="text.secondary"
          height={"32px"}
          maxWidth={"97px"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={20}
          bgcolor={
            data?.spots?.booked ? "#feedad" : "rgba(176, 220, 255, 0.50)"
          }
        >
          {`${data?.spots?.booked}/${data?.spots?.availableSpots} `}
          <span style={{ marginLeft: "2px" }} className="hideOnMobile">
            booked
          </span>
        </Typography>
        <MyButton
          onClick={(e) => {
            e?.preventDefault();
            e?.stopPropagation();
            // handlerUpdateBookingStatus(e, data);
            setSelectedData(data);
            setAcceptOfferDialog(true);
          }}
          className="checkIcon btn list-btn updateStatusBtn"
          color={offerStatusCode[data?.offerStatus]?.bg}
          sx={{
            borderRadius: "50px",
            fontWeight: 600,
            fontSize: "16px",
            height: "52px",
            width: "124px",
            bgcolor: "#e9d8ea",
            boxShadow: 0,
            ml: { xs: "auto", md: 1 },
          }}
          minWidth={"40px"}
          disabled={!(data?.offerStatus === "accept")}
        >
          <Box m={"8px 5px 0 0"}>
            {data?.offerStatus === "accept" ? (
              <CheckIcon sx={{ color: "primary.main" }} />
            ) : data?.offerStatus === "pending" ? (
              <materialIcons.clockIcon />
            ) : (
              <CheckIcon sx={{ color: "gray" }} />
            )}
          </Box>
          <span
            className={`hideOnMobile ${data?.offerStatus === "accept" && "textColor"
              }`}
          >
            {data?.offerStatus || "test"}
          </span>
        </MyButton>
        <IconButton
          className="BookMarkIcon"
          sx={{ height: "32px", position: "unset", p: 0 }}
          onClick={(e) => handlerSaveOffer(e, data)}
        >
          {influencerOfferFilter === "saved" || data?.isOfferSaved ? (
            <SvgIcon
              viewBox="0 0 34 34"
              component={SavedBookMarkIcon}
              sx={{ mr: 2, width: "34px", height: "34px" }}
            />
          ) : (
            // <img src={bookmarkIcon} style={{ color: "white" }} alt="loading" />
            <SvgIcon
              viewBox="0 0 34 34"
              component={BookMarkIcon}
              sx={{ mr: 2, width: "34px", height: "34px" }}
            />
          )}
        </IconButton>
      </>
    );
  };

  return (
    <Box position={"relative"}>
      <Box sx={{ padding: 1 }}>
        <Box className="influencerListHeader">
          <Box
            className="searchCtn hideOnMobile"
            display={"flex"}
            alignItems={"center"}
            sx={{ gap: "5px" }}
          >
            <SearchBar
              query={query}
              setQuery={setQuery}
              handlerFetchOffers={handlerFetchOffers}
              placeholder="Search offers"
            />
            <TuneIcon className="hideOnMobile" onClick={() => handleOpen()} />
          </Box>
          <Stack direction={"row"} className="influencerOfferTab">
            <Button
              className={`filterBtn btn ${influencerOfferFilter === "new" ? "filterActiveBtn" : ""
                }`}
              onClick={() => handleFilter("new")}
              color={"black"}
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "capitalize",
              }}
              variant="text"
            >
              New
            </Button>
            <MyButton
              onClick={() => handleFilter("saved")}
              className={`filterBtn btn ${influencerOfferFilter === "saved"
                ? "filterActiveBtn"
                : ""
                }`}
              color={"black"}
              sx={{
                minWidth: "50px",
                fontSize: "14px",
                fontWeight: 400,
              }}
              variant="text"
              font
            >
              Saved
            </MyButton>
            <MyButton
              onClick={() => handleFilter("expired")}
              className={` btn ${influencerOfferFilter === "expired" ? "filterActiveBtn" : ""
                }`}
              color={"black"}
              sx={{
                sm: { width: "60px" },
                fontSize: "14px",
                fontWeight: 400,
              }}
              variant="text"
            >
              Expiring
            </MyButton>
          </Stack>

          <Stack
            direction={"row"}
            gap={"5px"}
            alignItems={"center"}
            className="viewCtn"
            mr={1}
          >
            <IconButton
              className={` ${pageView === "list" ? "active-btn" : ""}`}
              onClick={() => handlePageView("list")}
              sx={{ height: "40px", width: "40px" }}
            >
              <ListIcon />
            </IconButton>
            <IconButton
              className={` ${pageView === "photo" ? "active-btn" : ""}`}
              onClick={() => handlePageView("photo")}
              sx={{ height: "40px", width: "40px" }}
            >
              <GridIcon />
            </IconButton>
          </Stack>
        </Box>
      </Box>
      <div className="offerCard">
        {pageView === "list" && (
          <>
            {/* <List
             className="fbkjdfdsfdff"
            height={700}
             itemCount={offersList?.length || 0}
            itemSize={100}
            width={"100%"}
            itemData={offersList}
          >
             {InfluencerOffersListVirtual}
          </List> */}
            {offersList.map((ele) => {
              return (
                <InfluencerOffersList
                  data={ele}
                  style={{ overFlow: "auto" }}
                  handleSelectOffersCard={handleSelectOffersCard}
                  module={"offer"}
                  handlerUpdateBookingStatus={handlerUpdateBookingStatus}
                  setSelectedData={setSelectedData}
                  handlerFetchOffers={handlerFetchOffers}
                  isSavedOffer={
                    influencerOfferFilter === "saved" ||
                    ele?.isOfferSaved ||
                    false
                  }
                  children={getChildren(ele)}
                  className={"offerTitle"}
                  ctnClassName={"offerListView"}
                />
              );
            })}
          </>
        )}
        {pageView === "photo" && (
          // changes here
          <div className="photoViewContainer">
            {offersList.map((ele, index) => {
              return (
                <PhotoView
                  style={{ overFlow: "auto" }}
                  data={ele}
                  handleSelectOffersCard={handleSelectOffersCard}
                  handlerFetchOffers={handlerFetchOffers}
                  handlerUpdateBookingStatus={handlerUpdateBookingStatus}
                  isSavedOffer={
                    influencerOfferFilter === "saved" ||
                    ele?.isOfferSaved ||
                    false
                  }
                  key={index}
                ></PhotoView>
              );
            })}
          </div>
        )}
        {offersList?.length === 0 && (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography fontWeight="700" variant="body1" color="primary">
              No data found.
            </Typography>
          </Box>
        )}
      </div>
      <MyButton
        onClick={() => {
          setOpen(true);
        }}
        className="addIcon btn hideOnWeb createOfferBtn"
        color="secondary"
        sx={{
          color: "white",
          fontWeight: 700,
          ml: "auto",
          // border: 1,
          // borderColor: "secondary",
          borderRadius: "16px",
          width: 56,
          bgcolor: "white.main",
          height: 56,
          boxShadow:
            "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 6px -1px rgba(0, 0, 0, 0.30), 0px 0px 4px -1px rgba(0, 0, 0, 0.06)",
          position: "absolute",
          bottom: 45,
          right: 12,
        }}
        startIcon={
          // <AddIcon margin={0} sx={{ margin: "0px", color: "#8F3A98" }} />
          <MySearchIcon margin={0} sx={{ margin: "0px", color: "#8F3A98" }} />
        }
      ></MyButton>
      {/* Filter modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          overflow={"auto"}
        >
          <Box
            className="filter-modal"
            sx={{
              width: "588px",
              bgcolor: "#ece6f0",
              boxShadow: 24,
              p: "5px 12px",
            }}
          >
            <Stack direction="row" justifyContent="space-between" mb={4}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                fontSize={"24px"}
                fontWeight={400}
              >
                Search offers
              </Typography>
              <IconButton color="primary" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              <Stack width={"100%"} direction="row" alignItems={"center"}>
                {/* <SvgIcon
                  component={OfferIcon}
                  sx={{ mr: 1, color: "primary.main" }}
                /> */}
                <LabelRoundedIcon
                  sx={{
                    color: "primary.main",
                    fill: "#ece6f0",
                    stroke: "#8F3A98",
                    strokeWidth: "1.5",
                    mr: 1,
                  }}
                />
                <Typography
                  width={"250px"}
                  maxWidth={"500px"}
                  color={"text.main"}
                  fontWeight="700"
                  fontSize={"18px"}
                  variant="body1"
                >
                  Type
                </Typography>
              </Stack>
              <Box
                height={"244px"}
                width={"100%"}
                overflow={"auto"}
                display={"flex"}
                flexWrap={"wrap"}
                gap={1}
              >
                {categories?.map((ele, index) => {
                  return (
                    <Box
                      height={"52px"}
                      display={"flex"}
                      alignItems={"center"}
                      onClick={() => handlerOnchange("categories", ele)}
                      className={
                        ele?.isSelected
                          ? "selectedCategory"
                          : "unselectedCategory"
                      }
                      color={"primary.main"}
                      borderRadius={"30px"}
                    >
                      {ele?.icon}
                      <Typography
                        width={"fitContent"}
                        color={"text.main"}
                        fontWeight="500"
                        fontSize={"16px"}
                        variant="body1"
                        mx={2}
                        sx={{ color: "primary" }}
                      >
                        {ele?.value}
                      </Typography>

                      {ele?.isSelected ? (
                        <RemoveIcon
                          className="hideOnMobile"
                          sx={{ width: 18, height: 18, mx: 2 }}
                        />
                      ) : (
                        <AddIcon
                          className="hideOnMobile"
                          sx={{ width: 18, height: 18, mx: 2 }}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Stack>
            <Stack>
              <Box display={"flex"} mt={1}>
                <CalenderIcon className="filterCalenderIcon" />
                <Typography
                  id="transition-modal-title"
                  variant="body1"
                  component="h2"
                  sx={{ fontWeight: "700", fontSize: "18px" }}
                >
                  Availability
                </Typography>
              </Box>

              <Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <LocalizationProvider
                    className="test11"
                    dateAdapter={AdapterDayjs}
                    sx={{ flexDirection: "row" }}
                  >
                    <DemoContainer
                      className="test"
                      components={["DatePicker"]}
                      sx={{ flexDirection: "row", width: "100%" }}
                    >
                      <Stack direction={"row"} gap={1} width={"100%"}>
                        <DatePicker
                          className="datePicker"
                          sx={{ minWidth: "136px", height: 40 }}
                          value={dateList?.toDate?.start || null}
                          onChange={(e) => {
                            handlerDateOnchange("toDate", "start", e);
                          }}
                          disablePast
                          label="Start date..."
                        />
                        <DatePicker
                          className="datePicker"
                          value={
                            dateList?.toDate?.end ||
                            dateList?.toDate?.start ||
                            null
                          }
                          minDate={dayjs(new Date(dateList?.toDate?.start)).add(
                            2,
                            "day"
                          )}
                          label="End date..."
                          onChange={(e) => {
                            handlerDateOnchange("toDate", "end", e);
                          }}
                        />
                      </Stack>
                    </DemoContainer>
                  </LocalizationProvider>
                </Stack>
              </Stack>
            </Stack>

            <Box
              component="form"
              sx={{
                "& > :not(style)": {
                  // m: 1,
                  width: "100%",
                  // height: "48px",
                  // borderBottom: "1px solid gray",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Box display={"flex"} mb={1}>
                <LocationOnOutlinedIcon sx={{ color: "primary.main", mr: 1 }} />
                <Typography
                  id="transition-modal-title"
                  variant="body1"
                  component="h2"
                  sx={{ fontWeight: "700", fontSize: "18px" }}
                >
                  Place
                </Typography>
              </Box>
              <LocationAutoComplete
                height={"100%"}
                setLocation={setLocation}
                value={location}
                setFilterLocation={setFilterLocation}
              />
            </Box>
            <Stack
              direction="row"
              spacing={2}
              mt={"auto"}
              justifyContent="space-between"
            >
              <MyButton
                sx={{
                  width: "50%",
                  borderRadius: "15px",
                  borderColor: "text.secondary",
                  color: "primary.main",
                  bgcolor: "#ffff",
                }}
                onClick={handleClearFilters}
              >
                Clear all
              </MyButton>
              <MyButton
                disabled={
                  !dateList?.fromDate?.start &&
                  !dateList?.toDate?.start &&
                  !filterDtls?.location?.length > 0 &&
                  !filterDtls?.categories?.length > 0 &&
                  !filterDtls?.name &&
                  !location?.locationName?.length > 0
                }
                onClick={() => {
                  handlerFetchOffers();
                  handleClose();
                }}
                sx={{
                  width: "50%",
                  borderRadius: "15px",
                  borderColor: "text.secondary",
                }}
              >
                Search
              </MyButton>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <Dialog
        // fullScreen={fullScreen}
        open={acceptOfferDialog}
        onClose={() => setAcceptOfferDialog(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          borderRadius: 50,
          display: "flex",
          justifyContent: "center",
          bgcolor: "transparent",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
        className="modalSection"
      >
        <Box
          className="acceptOfferModalCtn"
          sx={{
            p: 3,
            minWidth: 320,
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            textAlign={"center"}
            fontSize={24}
            fontWeight={400}
          >
            Accept offer
          </DialogTitle>
          <Typography color={"#49454F"} fontSize={14} fontWeight={400} my={2}>
            By accepting this Offer, you agree to the following request.
          </Typography>
          <Typography
            // className="hideOnMobile"
            display={"flex"}
            variant="body1"
            fontSize={14}
            fontWeight={700}
            height={"32px"}
            width={"89px"}
            alignItems={"center"}
            justifyContent={"center"}
            border={1}
            borderColor={"#CAC4D0"}
            borderRadius={20}
            color={"#8F3A98"}
          >
            {`${formatDate(
              selectedData?.availableDates?.from,
              "DD/MM"
            )}-${formatDate(selectedData?.availableDates?.to, "DD/MM")}`}
          </Typography>
          <Stack direction="row" spacing={2} mt={3}>
            <InstagramIcon sx={{ color: "#79747E" }} />
            <Typography fontSize={18} color={"#1D1B20"} fontWeight={700}>
              Instagram request
            </Typography>
          </Stack>
          <Stack spacing={2} mt={3}>
            {selectedData?.requirement?.find(
              (ele) => ele?.name.toLowerCase() === "post"
            )?.count > 0 && (
                <Typography
                  height={28}
                  width={232}
                  bgcolor={"#FFF"}
                  borderRadius={"20px"}
                  fontSize={16}
                  color={"#1D1B20"}
                  fontWeight={400}
                  textAlign={"center"}
                >{`Hard Posts ${selectedData?.requirement?.find(
                  (ele) => ele?.name.toLowerCase() === "post"
                )?.count
                  }`}</Typography>
              )}
            {selectedData?.requirement?.find(
              (ele) => ele?.name.toLowerCase() === "story"
            )?.count > 0 && (
                <Typography
                  height={28}
                  width={232}
                  bgcolor={"#FFF"}
                  borderRadius={"20px"}
                  fontSize={16}
                  color={"#1D1B20"}
                  fontWeight={400}
                  textAlign={"center"}
                >{`Story ${selectedData?.requirement?.find(
                  (ele) => ele?.name.toLowerCase() === "story"
                )?.count
                  }`}</Typography>
              )}
            {selectedData?.requirement?.find(
              (ele) => ele?.name.toLowerCase() === "reels"
            )?.count > 0 && (
                <Typography
                  height={28}
                  width={232}
                  bgcolor={"#FFF"}
                  borderRadius={"20px"}
                  fontSize={16}
                  color={"#1D1B20"}
                  fontWeight={400}
                  textAlign={"center"}
                >{`Reels ${selectedData?.requirement?.find(
                  (ele) => ele?.name.toLowerCase() === "reels"
                )?.count
                  }`}</Typography>
              )}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            mt={3}
            justifyContent="space-between"
          >
            <MyButton
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
                bgcolor: "#FFF",
                color: "#8F3A98",
              }}
              onClick={() => {
                setAcceptOfferDialog(false);
                setSelectedData({});
              }}
            >
              cancel
            </MyButton>

            <MyButton
              onClick={() => {
                handlerAcceptOffer();
              }}
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
              }}
            >
              Accept
            </MyButton>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  );
};

export default InfluencerOffers;
