import React, { useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import "./message.css";
import { useDispatch, useSelector } from "react-redux";
import noDataFound from "../../assets/image/noDataFound.png";
import avatar from "../../assets/image/avatar.png";
import Avatar from "@mui/material/Avatar";
import {
  useGetNotificationListMutation,
  useGetNotificationUpdateMutation,
  useGetMessageListMutation,
  useGetUserChatListMutation,
} from "../../services/apiService/userApiService";
import toaster from "../Toaster/toaster";
import { socket } from "../../services/Socket/Socket";
import useLoading from "../../hooks/useLoading";
import { setMessageCount } from "../../store/Slicers/messageSlice";
import { useNavigate } from "react-router-dom";
import materialIcons, { MessageArrowDownIcon, MessageArrowUpIcon } from "../../utils/materialIcon";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        height: "100%",
        overflow: "scroll"
      }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OutlinedCard = () => {
  const navigate = useNavigate();
  const { userDtls } = useSelector((state) => state?.auth);
  const { startLoading, stopLoading } = useLoading();
  // const [imageUpload] = useUploadImageMutation();
  const [updateNotification] = useGetNotificationUpdateMutation();
  const [getNotification] = useGetNotificationListMutation();
  const [getMessageList] = useGetMessageListMutation();
  const [chatFilter, setChartFilter] = useState('active')
  // const [saveMessage] = useSaveMessageMutation();
  const [conversation, setConversation] = React.useState([]);
  const [filterConversation, setFilterConversation] = useState([])
  const [notificationList, setNotificationList] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [dataNotFound, setDataNotFound] = React.useState(false);
  // const [message, setMessage] = useState(""); // Message input
  const [messages, setMessages] = useState([]); // Chat messages array
  const [usersDetails, setUsers] = useState([]);
  const [conversationTimeDetails, setConversationTime] = useState();
  const dispatch = useDispatch();
  const [currentDropDown, setCurrentDropDown] = useState(false)
  // const [image, setImage] = useState([]);

  socket.on("receivedMessage", (newMessage) => {
    const msg = [...messages, newMessage];
    setMessages(msg);
    // scrollToBottom();
  });

  useEffect(() => {
    conversationList();
    socket.connect();
    socket.emit("setup", { _id: userDtls?.userId });

    return () => {
      socket.disconnect();
      socket.off("new message");
      socket.off("notification");
    };
  }, [userDtls?.userId]);

  const conversationList = async () => {
    startLoading();
    try {
      const payload = {
        id: userDtls?.userId,
      };

      const response = await getMessageList(payload);
      if (response?.data?.data) {
        const formattedConversationList = response?.data?.data.map((ele) => {
          ele.userDetail =
            ele?.userDetail?.filter(
              (user) => user?.userId !== userDtls?.userId
            )[0] || {};
          return ele;
        });
        // setConversation(response?.data?.data);
        setConversation(formattedConversationList);
        setFilterConversation(formattedConversationList)
        setUsers(response?.data?.data?.[0]);
        const lastConversationTime = timeAgo(
          response?.data?.data?.[0]?.latestMessage?.createdAt
        );
        handlerChatFilter("active", formattedConversationList)
        setConversationTime(lastConversationTime);
      } else {
        toaster("error", response?.error?.data?.message || "No data");
      }
    } catch (error) {
      toaster("error", "Something went wrong");
    }
    stopLoading();
  };

  const timeAgo = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const now = new Date();
    const timeDifference = now - createdAtDate; // Time difference in milliseconds

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  /**Notification list api */
  const notificationData = async () => {
    try {
      const response = await getNotification({
        id: userDtls?.userId,
        filter: { sort: "createdAt" },
      }).unwrap();
      if (response?.data?.length) {
        const updatedData = response.data
          ?.filter((ele) => ele?.recipientId === userDtls?.userId)
          ?.map((item) => ({
            ...item,
            timeAgo: timeAgo(item.createdAt),
          }));
        setNotificationList(updatedData);
        dispatch(
          setMessageCount(
            updatedData?.filter((ele) => !ele?.isRead)?.length || 0
          )
        );
      }
    } catch (error) {
      if (error?.data?.message === "No records found") {
        toaster("error", "No records found");
        setDataNotFound(true);
      } else if (error?.data?.message === "Invalid request") {
        toaster("error", "Invalid request");
      } else {
        toaster("error", "Something went wrong");
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCardClick = async (selectedItem) => {
    const updatedItem = { ...selectedItem, isRead: true };
    try {
      const payload = {
        id: updatedItem?._id,
        ...updatedItem,
      };
      const responce = await updateNotification(payload);

      if (responce?.data) {
        notificationData();
      } else if (responce?.error?.data?.message === "Invalid request") {
        toaster("error", "Invalid request");
      }
    } catch (error) {
      toaster("error", "Something went wrong");
    }
  };

  const handleClickConversations = (arrayDetails) => {
    // userChatListData(arrayDetails);
    // setIsCompany(true);
    // setCmpyDetails(arrayDetails);
    navigate(`/chat/${arrayDetails?._id}`);
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    const container = document.getElementById("chat-container");
    if (container) {
      container.scrollTop = container.scrollHeight;
    } else {
      console.error('Element with class "chats-details" not found.');
    }
  };

  /**
   * To handle the chat filter
   */
  const handlerChatFilter = (key, data) => {
    const filterData = (data || conversation)?.filter(ele => ele?.chatStatus === key);
    setFilterConversation(filterData)
  }

  const handleClickCurrentDropDown = () => {
    setCurrentDropDown(!currentDropDown)
  }
  return (
    <Stack padding className="message-header">
      <Box sx={{ width: "100%" }} className="messageCtn">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Conversations"
              color="black"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Notifications"
              {...a11yProps(1)}
              onClick={() => notificationData()}
            />
          </Tabs>
        </Box>
        {value === 0 && <div className="current-dropdown">
          <div className="current"
            onClick={() => handleClickCurrentDropDown()}
          >
            <div>
              {
                chatFilter ?

                  <MessageArrowDownIcon />
                  :
                  <MessageArrowUpIcon />
              }
            </div>
            <div>
              <h1 className="current-text">{chatFilter === "active" ? "Current" : "Past"}</h1>
            </div>
            {currentDropDown && (
              <div className="current-dropdown-past" onClick={() => {
                handlerChatFilter(chatFilter !== "active" ? "active" : "inactive")
                setChartFilter(chatFilter === "active" ? "inactive" : "active")
              }}>
                <div className="current-past-text">
                  {chatFilter !== "active" ? "Current" : "Past"}
                </div>
              </div>
            )}
          </div>
        </div>}


        <TabPanel value={value} index={0} className="conversationTab">
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            {filterConversation?.map((item, index) => (
              <Card
                key={index}
                sx={{
                  borderRadius: "10px",
                  height: 82,
                  boxShadow:
                    " 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                }}
                className="conversationCard"
                onClick={() => handleClickConversations(item)} // Add onClick conversation
              >
                {/* <div className="Conversations"> */}
                {/* {item?.userDetail
                    ?.filter((value) => value?.userId !== userDtls?.userId)
                    .map((value, i) => (
                      <card key={i}> */}
                <Avatar
                  className="image-notification"
                  src={item?.userDetail?.profileImage || avatar}
                  sx={{ width: "46px", height: "46px" }}
                />
                {/* </card> */}
                {/* ))} */}
                {/* <div className="typo-Conversations"> */}
                {/* <div className="notification-title"> */}
                {/* {item?.userDetail
                        ?.filter((value) => value?.userId !== userDtls?.userId)
                        .map((value, i) => ( */}
                <Typography
                  variant="h5"
                  component="div"
                  alignSelf={"center"}
                  fontSize={14}
                  fontWeight={400}
                  color={"#625B71"}
                  sx={{
                    textTransform: "capitalize",
                    width: "130px",
                    wordBreak: "break-all",
                    marginLeft: 1,
                  }}
                >
                  {item?.userDetail?.username}
                </Typography>
                {/* ))} */}
                {/* <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  className="notification-hours"
                >
                  {conversationTimeDetails}
                </Typography> */}
                {/* </div> */}
                {/* <Button sx={{ width: "100px" }} variant="outlined">
                          {item.type}
                        </Button> */}
                {/* {item?.latestMessage?.content === "" ? (
                  <Avatar src={avatar}></Avatar>
                ) : ( */}
                <Typography
                  sx={{ textTransform: "capitalize", mt: "3px" }}
                  fontSize={14}
                  fontWeight={400}
                  className="lastMessage"
                  color="text.main"
                >
                  {item?.latestMessage?.content}
                </Typography>
                {/* )} */}
                {/* </div> */}
                {/* </div> */}
                <Box
                  className="lastSeen"
                  display={"flex"}
                  alignItems={"center"}
                  padding={1}
                >
                  <materialIcons.clockIcon
                    color="gray"
                    sx={{ mr: 1, ml: "auto", height: 18, width: 18 }}
                  />
                  <Typography
                    sx={{ fontSize: 14, color: "gray.main" }}
                    color="text.secondary"
                    gutterBottom
                    fontSize={12}
                    fontWeight={400}
                    mb={0}
                  >
                    {conversationTimeDetails}
                  </Typography>
                </Box>
              </Card>
            ))}
          </Box>
          {/* )} */}
        </TabPanel>
        <TabPanel value={value} index={1} className="content-tab">
          {notificationList.map((item, index) => {
            return (
              <Card
                key={index}
                height={68}
                sx={{
                  marginBottom: 2,
                }}
                style={{
                  backgroundColor: item?.isRead ? "white" : "#F9F5FA", // Set background color dynamically
                }}
                onClick={() => {
                  if (item?.isRead) {
                    return;
                  }
                  handleCardClick(item); // Proceed with the click action if not read
                }}
              >
                {/* <div className="notification"> */}
                {/* <img
                  className="image-notification"
                  component="img"
                  src={item?.sender?.image || avatar}
                  alt="loading"
                /> */}
                {/* <div className="typo-notification">
                  <div className="notification-title">
                    <Typography variant="h5" component="div">
                      {item?.sender?.username}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                      className="notification-hours"
                    >
                      {item?.timeAgo}
                    </Typography>
                  </div>
                  <Typography color="text.secondary">{item.message}</Typography>
                </div> */}
                <Stack flexDirection={"row"}>
                  <Typography
                    variant="h5"
                    component="div"
                    alignSelf={"center"}
                    fontSize={14}
                    fontWeight={400}
                    color={"#625B71"}
                    sx={{
                      // textTransform: "capitalize",
                      width: "130px",
                      wordBreak: "break-all",
                      marginLeft: 1,
                    }}
                  >
                    {"Notification type"}
                  </Typography>
                  <Box
                    className="lastSeen"
                    display={"flex"}
                    alignItems={"center"}
                    padding={1}
                    ml={"auto"}
                  >
                    <materialIcons.clockIcon
                      color="gray"
                      sx={{ mr: 1, ml: "auto", height: 18, width: 18 }}
                    />
                    <Typography
                      sx={{ fontSize: 14, color: "gray.main" }}
                      color="text.secondary"
                      gutterBottom
                      fontSize={12}
                      fontWeight={400}
                      mb={0}
                    >
                      {item?.timeAgo}
                    </Typography>
                  </Box>
                </Stack>
                <Typography
                  color={"#1D1B20"}
                  fontSize={14}
                  fontWeight={item.isRead ? 400 : 700}
                  ml={2}
                  mb={1}
                >
                  {item.message}
                </Typography>

                {/* </div> */}
              </Card>
            );
          })}
          {dataNotFound && <img src={noDataFound} alt="loading"></img>}
        </TabPanel>
        {/* <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="mobile-view-tabs"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Conversations" {...a11yProps(0)} />
            <Tab
              label="Notifications"
              {...a11yProps(1)}
              onClick={() => notificationData()}
            />
          </Tabs>
        </Box> */}
      </Box>
    </Stack>
  );
};

export default OutlinedCard;
