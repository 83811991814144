import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
// import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import InstagramIcon from "@mui/icons-material/Instagram";
import materialIcons from "../../utils/materialIcon";
import { Facebook } from "@mui/icons-material";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

const ConnectSocialMedia = ({
  userName,
  setStep,
  handlerInstagramLogin,
  handlerFacebookLogin,
  socialMediaOpenId,
  handlerGenerateOtp,
  props,
}) => {
  const [labelList] = useState([
    <Stack>
      <Typography>Connect with Instagram</Typography> Select and link your
      account
    </Stack>,
  ]);
  const initialFormValue = {
    termsAndConditions: {
      value: false,
    },
    privacy: {
      value: false,
    },
  };
  const [selectedSocialMedia, setSelectedSocialMedia] = useState("");
  const [isShowConformationModal, setIsShowConfirmationModal] = useState(false);
  const { userDtls, instagramData } = useSelector((state) => state.auth);
  const [formValues, setFormValues] = useState({ ...initialFormValue });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #b6b6b6",
    boxShadow: 24,
    borderRadius: 3,
    p: 4,
  };

  /**
   * To show the conforation modal
   * @param (social media name)
   * @returns Jsx element
   */
  const ConformationModal = () => {
    return (
      <Modal
        padding={1}
        open={isShowConformationModal}
        onClose={() => setIsShowConfirmationModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          padding={1}
          sx={style}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <CloseIcon
            onClick={() => setIsShowConfirmationModal(false)}
            className="modal-Close-icon"
          />
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {`Connect ${selectedSocialMedia}`}
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {userName}
          </Typography>
          <Divider
            sx={{
              marginTop: 1,
            }}
          />
          <Stack direction={"row"} gap={2}>
            <Button
              sx={{ textTransform: "capitalize" }}
              variant="contained"
              color="secondary"
              onClick={() => setIsShowConfirmationModal(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{ textTransform: "capitalize" }}
              variant="contained"
              onClick={() => selectedSocialMedia === 'Instagram' ? handlerInstagramLogin() : handlerFacebookLogin()}
            >
              Connect
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      mt={4}
      height={" calc(100% - 240px)"}
    >
      <ConformationModal />
      {/* <Avatar
        sx={{ height: "96px", width: "96px" }}
        src={logosyme}
        alt="loading"
      ></Avatar> */}
      <Typography
        variant="h5"
        padding={1}
        textAlign="center"
        letterSpacing={"-0.01em"}
        fontWeight={500}
        mb={2}
        fontSize={16}
        sx={{
          color: "#8F3A98",
        }}
      >
        Create an influencer account
      </Typography>
      {/* <Divider
        sx={{ width: "80%", mb: 10 }}
        component="div"
        role="presentation"
      /> */}

      {/* <Container justifyContent="center" maxWidth="sm"> */}
      {/* <Stack justifyContent="center" alignItems="center" spacing={2}> */}
      {/* {instagramData?.id ? (
        <>
          {" "}
          <Button
            sx={{ textTransform: "capitalize", width: "60%" }}
            variant="contained"
            color="light1"
          >
            {instagramData?.username}
          </Button>
        </>
      ) : (
        <>
          {labelList?.map((ele) => {
            return (
              <Button
                sx={{
                  textTransform: "capitalize",
                  width: "336px",
                  color: "#8F3A98",
                  borderRadius: "16px",
                  mb: 2,
                }}
                onClick={() => {
                  console.log("clicked");
                  setIsShowConfirmationModal(true);
                  setSelectedSocialMedia("Instagram");
                }}
                variant="contained"
                color="light1"
                startIcon={<InstagramIcon />}
              >
                {ele}
              </Button>
            );
          })}
        </>
      )} */}
      {/* <Button
        sx={{
          textTransform: "capitalize",
          width: "336px",
          color: "#8F3A98",
          borderRadius: "16px",
          mb: 2,
        }}
        onClick={() => {
          setIsShowConfirmationModal(true);
          setSelectedSocialMedia("Facebook");
        }}
        variant="contained"
        color="light1"
        startIcon={< Facebook />}
      >
        Connect with facebook Select and link your
        account
      </Button> */}
      <div role="button" onClick={() => {
        setIsShowConfirmationModal(true);
        setSelectedSocialMedia("Facebook");
      }} class="fb-login-button" data-width="400" data-size="" data-layout="" data-auto-logout-link="false" data-use-continue-as="false"><FacebookOutlinedIcon />Continue With Facebook</div>
      <Stack>
        <FormControlLabel
          sx={{ margin: 0, fontSize: "0.9rem", color: "#8F3A98" }}
          label="I have read and accept the Terms and conditions"
          control={
            <Checkbox
              name="checked"
              checked={formValues?.termsAndConditions?.value}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  termsAndConditions: {
                    value: e?.target?.checked,
                  },
                })
              }
            />
          }
        />
        <FormControlLabel
          sx={{
            margin: 0,
            fontSize: "12px",
            fontWeight: 400,
            color: "#8F3A98",
          }}
          fon
          label="I have read and accept the Privacy Policy"
          control={
            <Checkbox
              name="checked"
              checked={formValues.privacy.value}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  privacy: {
                    value: e?.target?.checked,
                  },
                })
              }
            />
          }
        />
      </Stack>

      {/* </Stack> */}
      {/* </Container> */}
      <Stack direction={"row"} gap={2} marginTop={"auto"}>
        <>
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "capitalize",
              color: "primary.main",
              height: 56,
              width: 162,
              borderRadius: 4,
              bgcolor: "white.main",
            }}
            size="large"
            onClick={() => setStep("signUp")}
          >
            Back
          </Button>
          <Button
            endIcon={<materialIcons.rightArrow />}
            disabled={
              !instagramData?.username ||
              !formValues?.termsAndConditions?.value ||
              !formValues?.privacy?.value
            }
            variant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "capitalize",
              height: 56,
              width: 162,
              borderRadius: 4,
            }}
            size="large"
            onClick={() => {
              // handlerGenerateOtp();
              setStep("uploadProfile");
              // handlerSignUp();
            }}
          >
            Next
          </Button>
        </>
      </Stack>
    </Box>
  );
};
export default ConnectSocialMedia;
