import * as React from "react";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import TodayIcon from "@mui/icons-material/Today";
import MyButton from "../../MyButton";
import { formatDate } from "../../../utils/common";
import { useSelector } from "react-redux";
import { useSaveOfferMutation } from "../../../services/apiService/userApiService";
import toaster from "../../Toaster/toaster";
import materialIcons, {
    BookMarkIcon,
    SavedBookMarkIcon,
} from "../../../utils/materialIcon";
import completedIcon from "../../../assets/Icons/completedIcon.png";
import "../../../style/CommonStyleNew.css";
import { SvgIcon } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, useMediaQuery, useTheme } from "@mui/material";

const HomePhotoView = ({
    handleSelectOffersCard,
    data,
    handlerFetchOffers,
    handlerUpdateBookingStatus,
    isSimilarView = false,
    noOption,
    isSavedOffer,
    HomePagePhotoCardCtn,
    isHome,
}) => {
    console.log("data", data);
    const isExtraSmall = useMediaQuery("(max-width:480px)"); // Custom breakpoint for extra small screens
    const isSmall = useMediaQuery("(max-width:768px)"); // Custom breakpoint for small screens
    const isMedium = useMediaQuery("(max-width:1024px)"); // Custom breakpoint for medium screens
    const isLarge = useMediaQuery("(max-width:1256px)"); // Custom breakpoint for medium screens
    const navigate = useNavigate();
    const { userDtls } = useSelector((state) => state?.auth);
    const location = useLocation();
    const [saveOffer] = useSaveOfferMutation();
    const isMobile = useMediaQuery("(max-width:768px)");
    // ! changes here
    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handlerSaveOffer = async (e) => {
        try {
            e?.stopPropagation();
            e?.preventDefault();
            const payload = {
                offer_id: data?._id,
                // newStatus: "offeredToInfluencer",
                user_id: userDtls?.userId,
            };
            const response = await saveOffer(payload).unwrap();
            if (response?.success) {
                handlerFetchOffers(true);
                toaster("info", "Offer successfully saved");
            } else {
                toaster("info", "Offer failed to saved");
            }
        } catch (err) {
            if (err?.data?.message) {
                toaster("error", err?.data?.message);
            } else {
                console.error("Failed to save offer:", err);
                toaster("error", "Something went wrong");
            }
        }
    };
    return (
        <>
            <Box
                onClick={(e) => {
                    navigate(`/offers/${data?._id}`);
                }}
                className={
                    isSimilarView
                        ? `similarPhotoCardCtn ${HomePagePhotoCardCtn && "HomePagePhotoCardCtn"
                        } `
                        : "PhotoCardCtnHome"
                }
                variant="contained"
                component="section"
                display="flex"
                flexDirection={"column"}
                // p={"5px"}
                sx={{
                    boxShadow: 2,
                    // p: 1,
                    borderRadius: "4px",
                    // margin: "5px",
                    bgcolor: "white.main",
                    overflow: "unset",
                    position: "relative",
                }}
            >
                {/* Top Section - Image with overlay icons */}
                <Box sx={{ height: "100%", position: "relative" }}>
                    <Box
                        sx={{
                            height: "100%",
                            width: "100%",
                        }}
                        className="cardMediaCtn"
                    >
                        {/* <CarouselView
            handleSelectOffersCard={handleSelectOffersCard}
            imageList={data?.offerImages}
          /> */}
                        <CardMedia
                            // onClick={
                            // module === "offer" ? () => handleSelectOffersCard(data) : null
                            // }
                            className="CardMedia"
                            component="img"
                            alt="green iguana"
                            width={"100%"}
                            image={data?.offerImages?.[0]}
                            sx={{
                                borderRadius: "6px",
                                objectFit: "cover",
                                height: `${location.pathname === "/offers" &&
                                    userDtls.type === "influencer" &&
                                    isMobile
                                    ? "310px"
                                    : "100%"
                                    }`,
                            }}
                        />
                        {/* Save icon and buttons on top */}

                        {
                            <Box
                                // className="card-tag"
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                sx={{
                                    p: 1,
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    // bgcolor: "rgba(0, 0, 0, 0.2)",
                                    zIndex: 11,
                                    // backdropFilter: "blur(1px)",
                                    // background: "rgba(255, 255, 255, 0.2)",
                                }}
                            >
                                {isSimilarView && !HomePagePhotoCardCtn && !isMobile && (
                                    <CheckIcon
                                        sx={{ ml: "auto" }}
                                        className="mobileTextPrimary"
                                    />
                                )}
                                {
                                    (!isMobile || !isSimilarView) &&
                                    <>
                                        <MyButton
                                            // className="hideOnMobile  "
                                            // className="hideOnWeb"
                                            sx={{
                                                height: "32px",
                                                boxShadow: 0,
                                                // padding: "8px",
                                                bgcolor: "rgba(0, 0, 0,0)",
                                                ml: "auto",
                                            }}
                                            startIcon={<TodayIcon color="white" />}
                                        >
                                            <Typography
                                                color="white.main"
                                                // className="hideOnMobile  "
                                                display={"flex"}
                                                variant="body1"
                                                fontSize={12}
                                                fontWeight={700}
                                                font-family={"Roboto Mono"}
                                                height={"32px"}
                                                // width={"89px"}
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                            >
                                                {`${formatDate(
                                                    data?.availableDates?.from,
                                                    "DD/MM"
                                                )} - ${formatDate(data?.availableDates?.to, "DD/MM")}`}
                                            </Typography>
                                        </MyButton>

                                        {HomePagePhotoCardCtn && (
                                            <IconButton
                                                role="button"
                                                sx={{
                                                    height: "32px",
                                                    padding: "8px",
                                                    bgcolor: "rgba(0, 0, 0,0)",
                                                }}
                                                color="white"
                                                onClick={(e) => handlerSaveOffer(e)}
                                            ></IconButton>
                                        )}
                                    </>
                                }
                                {isSimilarView && !isMobile && (
                                    <>
                                        <CheckIcon className="mobileTextPrimary" />
                                    </>
                                )}
                                {!isSimilarView && isMobile && (
                                    <>
                                        <CheckIcon className="mobileTextPrimary" />
                                    </>
                                )}
                            </Box>
                        }
                    </Box>
                </Box>
                {(location.pathname === "/home" || userDtls?.type === "influencer") && (
                    <div
                        style={{
                            position: "absolute",
                        }}
                    >
                        <IconButton
                            role="button"
                            sx={{
                                height: "32px",
                                padding: "8px",
                                bgcolor: "rgba(0, 0, 0,0)"
                            }}
                            color="white"
                            onClick={(e) => handlerSaveOffer(e)}
                        >
                            {isSavedOffer ? (
                                <SvgIcon component={SavedBookMarkIcon} sx={{ mr: 1 }} />
                            ) : (
                                <SvgIcon component={BookMarkIcon} sx={{ mr: 1 }} />
                            )}
                        </IconButton>
                    </div>
                )}
                <Box className="photoViewSection2">
                    {((isMobile && isHome && !isSimilarView) ||
                        (!isMobile && isHome) ||
                        (isMobile && !isHome) ||
                        (!isMobile && !isSimilarView && !isHome) ||
                        (isSimilarView && !isHome && !isMobile)) && (
                            <>
                                <Typography
                                    className={`photCardTitle  ${!isSimilarView && "textWhite"}`}
                                    fontWeight="600"
                                    fontSize={16}
                                    variant="body1"
                                    position={isMobile ? "relative" : ""}
                                    top={isMobile ? "100%" : ""}
                                    color={`${location.pathname === "/offers" &&
                                        userDtls.type === "influencer" &&
                                        isMobile
                                        ? "black.main"
                                        : "white.main"
                                        }`}
                                >
                                    {(() => {
                                        let charLimit;
                                        if (isExtraSmall) charLimit = 30; // Max characters for extra small devices
                                        else if (isSmall) charLimit = 40; // Max characters for small devices
                                        else if (isLarge) charLimit = 60; // Max characters for medium devices
                                        else charLimit = 100; // Default for larger devices

                                        return data?.title?.length > charLimit
                                            ? `${data.title.slice(0, charLimit)}...`
                                            : data?.title;
                                    })()}
                                </Typography>
                                <Typography
                                    className="mobile-WidthFitContent photoViewBookingBtn "
                                    padding={"6px 12px"}
                                    display={"flex"}
                                    variant="body1"
                                    fontSize={14}
                                    fontWeight={500}
                                    // width={"89px"}
                                    ml={"auto"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    borderRadius={20}
                                    bgcolor={
                                        data?.spots?.booked == data.spots.availableSpots ? "#FED7AD" : data?.spots?.booked ? "#feedad" : "rgba(176, 220, 255, 0.5)"
                                    }
                                    sx={{
                                        // marginLeft: `${isMobile ? "39% !important" : ""}`,
                                        // marginBottom: `${isMobile ? "20% !important" : ""}`,
                                        // marginRight: `${isMobile ? "10% !important" : ""}`,
                                        marginLeft: isExtraSmall
                                            ? "65%" // For devices under 480px width
                                            : isSmall
                                                ? "60%" // For devices under 768px width
                                                : isMedium
                                                    ? "47%" // For devices under 1024px width
                                                    : "auto", // Default for larger devices
                                        marginBottom: isExtraSmall
                                            ? "15% !important"
                                            : isSmall
                                                ? "20% !important"
                                                : isMedium
                                                    ? "9%"
                                                    : "0",
                                        marginRight: isExtraSmall ? "15%" : isSmall ? "20%" : "5%",


                                    }}
                                >
                                    {`${data?.spots?.booked}/${data?.spots?.availableSpots} `}
                                    <span style={{ marginLeft: "2px" }} className="hideOnMobile">
                                        booked
                                    </span>
                                </Typography>


                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    position={isMobile ? "relative" : ""}
                                    top={isMobile ? "100%" : ""}
                                    sx={{
                                        backgroundColor: isMobile
                                            ? "rgba(255, 255, 255, 0.3)"
                                            : "rgba(202, 196, 208, 0.2)", // Background color based on device type
                                        borderRadius: "18px", // Highly rounded for a pill shape
                                        padding: isMobile ? "6px 16px" : "6px 16px", // Adjust padding to create tighter wrapping
                                        // gap: isMobile ? "6px" : "",
                                        marginTop: isMobile ? "10px" : "5px", // Adjusted to improve alignment with the title
                                        marginBottom: isMobile ? "5px" : "10px", // Balanced bottom margin
                                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for better depth
                                        maxWidth: "fit-content", // Ensure the box wraps tightly around the text
                                        height: "40%", // Automatically adjusts height
                                        border: "1px solid rgba(255, 255, 255, 0.2)", // Optional border for a polished look
                                    }}
                                >
                                    <Typography
                                        className={`textEllipsis mobile-WidthFitContent ${!isSimilarView && "textWhite"
                                            }`}
                                        fontWeight="600" // Consistent boldness for text
                                        fontSize={isMobile ? 12 : 14} // Adjusted font size for devices
                                        variant="body2"
                                        color={`${location.pathname === "/offers" &&
                                                userDtls.type === "influencer" &&
                                                isMobile
                                                ? "black.main"
                                                : "white.main"
                                            }`}
                                        sx={{
                                            lineHeight: "1.2",
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            padding: isMobile ? "4px 0" : "2px 0", // Add vertical padding to adjust position
                                        }}
                                    >
                                        {`by ${data?.createdBy?.[0]?.username || data?.companyDetails?.username || "Company TESTC"}`}
                                    </Typography>
                                </Box>
                                {(!isSimilarView ||
                                    (isHome && !isMobile) ||
                                    (isHome && isMobile && !isSimilarView)) && (
                                        <Typography
                                            className={`textEllipsis mobile-WidthFitContent ${!isSimilarView && "textWhite"
                                                }`}
                                            fontWeight="600" // Consistent boldness for text
                                            fontSize={isMobile ? 12 : 14} // Adjusted font size for devices
                                            variant="body2"
                                        >
                                            <MyButton
                                                onClick={(e) => handlerUpdateBookingStatus(e, data)}
                                                className={`checkIcon btn list-btn mobileTextPrimary ${isMobile && isHome
                                                    ? "photoStatusBtnBlack"
                                                    : "photoStatusBtn"
                                                    }`}
                                                // color={offerStatusCode[data?.offerStatus]?.bg}
                                                sx={{
                                                    color: "white.main",
                                                    borderRadius: "50px",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    height: "46px",
                                                    // width: "124px",
                                                    // bgcolor: "black.main",
                                                    boxShadow: 0,
                                                    maxWidth: isMobile ? "90%" : "90%",
                                                    marginBottom: `${isMobile ? "3% !important" : ""}`,
                                                    marginLeft: isMobile
                                                        ? "170px !important"
                                                        : `calc(90% - ${isLarge ? "200px" : "150px"})`,
                                                    marginBottom: isExtraSmall
                                                        ? "15% !important"
                                                        : isSmall
                                                            ? "20% !important"
                                                            : isMedium
                                                                ? "9%"
                                                                : "15%",
                                                    marginRight: isExtraSmall ? "15%" : isSmall ? "20%" : "5%",
                                                    fontSize: "1rem !important",
                                                    lineHeight: "1.2",
                                                    textAlign: "center",
                                                    whiteSpace: "nowrap",
                                                    padding: isMobile ? "4px 0" : "2px 0",
                                                }}
                                                minWidth={"40px"}
                                                disabled={(data?.requestToBusiness.includes(userDtls.userId) || data?.status !== "active" || data?.spots?.availableSpots <= data?.spots?.booked)}
                                            >

                                                {isMobile ? (
                                                    <Box m={"8px 5px 0 0"} sx={{
                                                        marginBottom: "20px !important",
                                                    }}>

                                                        {data?.offerStatus === "accept" ? (
                                                            <CheckIcon className="mobileTextPrimaryHome" sx={{ fontSize: "1.5rem", marginTop: "80% !important" }} />
                                                        ) : data?.offerStatus === "pending" ? (
                                                            <materialIcons.clockIcon className="mobileTextPrimary" sx={{
                                                                fontSize: "1.5rem"
                                                            }} />
                                                        ) : (
                                                            <img
                                                                alt="loading"
                                                                src={completedIcon}
                                                                className="mr-auto"
                                                            ></img>
                                                        )}
                                                    </Box>
                                                ) : (
                                                    <Box m={"8px 5px 0 0"}>
                                                        {/* Else logic for non-mobile view */}
                                                        {data?.offerStatus === "accept" ? (
                                                            <CheckIcon className="desktopTextPrimaryHome" sx={{ fontSize: "1.5rem" }} />
                                                        ) : data?.offerStatus === "pending" ? (
                                                            <materialIcons.clockIcon className="desktopTextPrimary" />
                                                        ) : (
                                                            <img
                                                                alt="loading"
                                                                src={completedIcon}
                                                                className="mr-auto"
                                                            ></img>
                                                        )}
                                                    </Box>
                                                )}

                                                <span className="mobileTextPrimaryHome" >
                                                    {"Accept Offer"}
                                                </span>
                                            </MyButton>
                                        </Typography>
                                    )}
                            </>
                        )}
                </Box>
            </Box>
        </>
    );
};

export default HomePhotoView;
