import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const instagramClientId = process.env.REACT_APP_INSTAGRAM_APP_ID;
const instagramClientSecret = process.env.REACT_APP_INSTAGRAM_APP_SECRET;
const facebookClientId = process.env.REACT_APP_FACEBOOK_APP_ID;
const facebookClientSecret = process.env.REACT_APP_FACEBOOK_APP_SECRET;
// const instagramRedirectUrl = process.env.INSTAGRAM_REDIRECT_URL;

export const oauthApiService = createApi({
  reducerPath: "apiService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_HOST || "http://localhost:3001/",
  }),
  endpoints: (builder) => ({
    instagramOauth: builder.mutation({
      query: () => ({
        url: `user/instagram-oauth`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getInstagramAccessToken: builder.mutation({
      query: (data) => ({
        url: `user/instagram-accesstoken`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
    }),
    // getInstagramLongLiveToken: builder.mutation({
    //   query: (data) => ({
    //     url: `https://graph.instagram.com/access_token?grant_type=ig_exchange_token &client_secret=${instagramClientSecret}&access_token=${data}`,
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }),
    // }),
    getInstagramLongLiveToken: builder.mutation({
      query: (accessToken) => ({
        url: `user/facebook-longlive-token`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: { accessToken }
      }),
    }),
    getFacebookMeAccountData: builder.mutation({
      query: (access_token) => ({
        url: `https://graph.facebook.com/v20.0/me/accounts?fields=instagram_business_account,access_token,name,picture{url}&access_token=${access_token}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        // body: data,
      }),
    }),
    getInstagramUserId: builder.mutation({
      query: ({ pageId, accessToken }) => ({
        url: `https://graph.facebook.com/v20.0/${pageId}?fields=instagram_business_account&access_token=${accessToken}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        // body: data,
      }),
    }),
    getInstagramUserData: builder.mutation({
      query: ({ userId, accessToken }) => ({
        // url: `https://graph.instagram.com/v20.0/me?fields=user_id,username,followers_count&access_token=${data}`,
        url: `https://graph.facebook.com/v20.0/${userId}?fields=id,username,followers_count,media_count&access_token=${accessToken}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        // body: data,
      }),
    }),
    // getInstagramUserData: builder.mutation({
    //   query: (data) => ({
    //     url: `https://graph.instagram.com/v20.0/me?fields=user_id,username,followers_count&access_token=${data}`,
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     // body: data,
    //   }),
    // }),
    findUserByInstagramOpenId: builder.mutation({
      query: (data) => ({
        url: `user/loginInstagram`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
    }),
    updateSocialMediaOpenId: builder.mutation({
      query: (data) => ({
        url: `userobject/addSocialLoginInstagram`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
    }),
    facebookOauth: builder.mutation({
      query: () => ({
        url: `user/facebook-oauth`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});
export const {
  // Instagram
  useInstagramOauthMutation,
  useGetInstagramAccessTokenMutation,
  useGetInstagramUserIdMutation,
  useGetInstagramUserDataMutation,
  useGetFacebookMeAccountDataMutation,
  useFindUserByInstagramOpenIdMutation,
  useGetInstagramLongLiveTokenMutation,
  useUpdateSocialMediaOpenIdMutation,
  // Facebook
  useFacebookOauthMutation,
} = oauthApiService;
