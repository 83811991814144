import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import InboxIcon from "@mui/icons-material/Inbox";
import TuneIcon from "@mui/icons-material/Tune";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { Mail } from "@mui/icons-material";
import { useNavigate, useLocation, useHref } from "react-router-dom";
import defaultProfileImg from "../../assets/image/defaultProfileImg.png";
import GroupIcon from "@mui/icons-material/Group";
import "./Sidebar.css";
import logosyme from "../../assets/image/logosyme.png";
import { Button, CardMedia, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import symeLogo from "../../assets/image/symeLogo1.png";
import {
  setClearUserDtls,
  setUserDetails,
} from "../../store/Slicers/authSlice";
import { useGetNotificationListMutation } from "../../services/apiService/userApiService";
import LogoutIcon from "@mui/icons-material/Logout";
import { setMessageCount } from "../../store/Slicers/messageSlice";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  HomeIcon,
  OfferIcon,
  BookMarkIcon,
  SavedBookMarkIcon,
  BookingIcon,
  MessageIcon,
  SettingsIcon,
  StickyNoteIcon,
  TermsAndConditionsIcon
} from "../../utils/materialIcon";
import HelpCenterOutlinedIcon from "../../assets/image/help.jpg";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const drawerWidth = 360;
const drawerWidthTablet = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

/**
 * @returns open or close
 * */
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  [theme.breakpoints.down("md")]: {
    width: drawerWidthTablet,
    "& .MuiDrawer-paper": {
      width: drawerWidthTablet,
    },
  },
}));

const Sidebar = ({ open, handleDrawerToggle }) => {
  const dispatch = useDispatch(); //here used to dispatch the notification unread message and logout
  const navigate = useNavigate(); //navigate to the path of the sidebar
  const location = useLocation(); //for highlight the path
  const [isReadList, setIsReadList] = React.useState([]); // get notification list
  const { messageCount } = useSelector((state) => state?.message); //get message count from redux
  const { userDtls } = useSelector((state) => state?.auth); //get user details from redux
  console.log("userDtls", userDtls);


  const [getNotification] = useGetNotificationListMutation(); //api for get notification

  /**
   * logout the user
   * @returns empty
   * */
  const handleLogout = () => {
    dispatch(setClearUserDtls());
  };
  /**
   * navigate to the path
   * @returns path
   * */
  const handleNavigation = (path) => {
    navigate(path);
  };

  /**
   * api call for get notification
   * @returns data
   * */
  const notificationData = async () => {
    try {
      const response = await getNotification({
        id: userDtls?.userId,
        // id:'3256686d-fdcc-4a45-b2ec-4471f13a6d92',   //for testing
        filter: { sort: "createdAt" },
      }).unwrap();

      if (response?.data?.length) {
        const unreadMessages = response?.data?.filter(
          (message) => !message.isRead
        );
        dispatch(setMessageCount(unreadMessages?.length || ""));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // call the notification funtion api
  useEffect(() => {
    notificationData();
  }, []);

  return (
    <Box sx={{ display: "flex", bgcolor: "#65558fad" }}>
      <CssBaseline />
      <Drawer
        className="side-bar-for-web"
        variant="permanent"
        open={open}
        sx={{
          color: "black.main",
          bgcolor: "white", // Replace with your desired background color
          "& .MuiDrawer-paper": {
            bgcolor: "white", // Ensure the paper inside the drawer also has the same background color
            border: "0px",
            boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
            maxWidth: "360px"
          },
        }}
      >
        {/* header icon for shirnk */}
        <DrawerHeader
          sx={{
            bgcolor: "secondary.soft",
            color: "white",
            width: "330px",
            height: "108px",
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        // onClick={handleDrawerToggle}
        // className="toggle-bar"
        >
          {/* <IconButton>
            {open ? (
              <div className="icon-syme">
                <img src={logosyme} alt="SYME" className="logo-img" />
                <Typography sx={{ color: "#ffffff" }}>SYME</Typography>
              </div>
            ) : (
              <MenuIcon />
            )}
          </IconButton> */}
          <Box
            height={"84px"}
            width={"312px"}
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            gap={1}
            sx={{
              boxShadow:
                "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
              borderRadius: "16px",
            }}
            onClick={() => navigate("/profile")}
          >
            <CardMedia
              className="test"
              component="img"
              alt="green iguana"
              height={"60px"}
              width={"60px"}
              image={userDtls?.profileImage || defaultProfileImg}
              sx={{
                width: "60px",
                borderRadius: "50%",
                minWidth: "60px",
                ml: 2,
              }}
            />
            <Typography
              display={"flex"}
              className={"textEllipsis mobile-WidthFitContent"}
              // width={"140px"}
              height={20}
              color={"#4A4459"}
              fontWeight="600"
              fontSize={"14px"}
              variant="body1"
              alignItems={"center"}
              justifyContent={"center"}
            >
              {`@${userDtls?.firstName}${userDtls?.lastName}`}
            </Typography>
          </Box>
        </DrawerHeader>

        {/* <Divider sx={{ background: "white" }} /> */}
        {/* sidebar choose path */}
        <List
          className="component-list"
          sx={{ color: "black.main", fontSize: "14px" }}
        >
          {
            [
              // Influencer-specific items
              userDtls?.type === "influencer" && [
                {
                  text: "Home",
                  icon:  <HomeIcon />,
                  path: "/home",
                },
                {
                  text: "Offers",
                  icon: 
                  (
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "24px", height: "24px" }}
                    >
                      <defs>
                        <linearGradient id="gradientBorder" x1="0%" y1="0%" x2="100%" y2="100%">
                          <stop offset="0%" stopColor="#8F3A98" /> {/* Purple */}
                          <stop offset="100%" stopColor="#8F3A98" /> {/* Red */}
                        </linearGradient>
                        <linearGradient id="gradientBorderInactive" x1="0%" y1="0%" x2="100%" y2="100%">
                          <stop offset="0%" stopColor="#625B71" /> {/* Purple */}
                          <stop offset="100%" stopColor="#625B71" /> {/* Red */}
                        </linearGradient>
                      </defs>
                      <OfferIcon
                        sx={{
                          fill: "url(#gradientDesktop)",
                          fontSize: "inherit",
                        }}
                      />
                    </svg>
                  ),
                  path: "/offers",
                },
                {
                  text: "Bookings",
                  icon: <BookingIcon />,
                  path: "/bookings",
                },
                {
                  text: "Messages",
                  icon: <MessageIcon />,
                  path: "/message",
                  badge: messageCount || "",
                },
              ],

              // Non-influencer items
              userDtls?.type !== "influencer" && [
                {
                  text: "Bookings",
                  icon: 
                  (
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "24px", height: "24px" }}
                    >
                      <defs>
                        <linearGradient id="gradientBorder" x1="0%" y1="0%" x2="100%" y2="100%">
                          <stop offset="0%" stopColor="#8F3A98" /> {/* Purple */}
                          <stop offset="100%" stopColor="#8F3A98" /> {/* Red */}
                        </linearGradient>
                        <linearGradient id="gradientBorderInactive" x1="0%" y1="0%" x2="100%" y2="100%">
                          <stop offset="0%" stopColor="#625B71" /> {/* Purple */}
                          <stop offset="100%" stopColor="#625B71" /> {/* Red */}
                        </linearGradient>
                      </defs>
                      <BookingIcon
                        sx={{
                          fill: "url(#gradientDesktop)",
                          fontSize: "inherit",
                        }}
                      />
                    </svg>),
                  path: "/bookings",
                },
                {
                  text: "Offers",
                  icon: <OfferIcon color="#625b71" />,
                  path: "/offers",
                },
                {
                  text: "Messages",
                  icon: <MessageIcon />,
                  path: "/message",
                  badge: messageCount || "",
                },
              ],

              // Admin-specific items
              userDtls?.type === "admin" && [
                {
                  text: "Users",
                  icon: <GroupIcon sx={{ fill: "#CAC4D0", stroke: "currentcolor" }} />,
                  path: "/users",
                },
                {
                  text: "Reports",
                  icon: <StickyNoteIcon sx={{ fill: "#CAC4D0", stroke: "currentcolor" }} />,
                  path: "/reports",
                },
              ],
            ]
              .flat() // Flatten the array to remove nested arrays
              .filter(Boolean) // Remove falsy values (null or undefined)
              .map((item) => (
                <ListItem
                  key={item.text}
                  disablePadding
                  className={`test ${location.pathname.includes(item?.path) ? "active" : "inactive"}`}
                  >
                  <ListItemButton
                    className={`list-item-button ${location.pathname.includes(item?.path) ? "active" : "inactive"}`}
                    onClick={() => handleNavigation(item.path)}
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontWeight: location.pathname.includes(item.path) ? "bold" : "normal", // Bold text if selected
                      }
                    }}
                  >
                    <ListItemIcon
                      className="itemicon"
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                        paddingTop: `${item.text === "Home" ? "8px" : ""}`,
                      }}
                    >

                      {item.icon}

                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {item.badge && messageCount > 0 && (
                      <Badge
                        sx={{ marginLeft: 1, right: 50}}
                        badgeContent={`${item.badge} new`}
                        color={item.badge > 0 ? "primary" : ""}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              ))
          }
        </List>


        <Divider sx={{ background: "white", marginX: "20px", borderWidth: "1px" }} />

        {/* For logout and help, term is there  */}
        <List
          className="component-list"
          sx={{ color: "black.main", fontSize: "14px" }}
        >
          {[
            {
              text: "Settings",
              icon: <SettingsIcon />,
              path: "/settings",
            },
            {
              text: "Help & Support",
              icon: <HelpCenterOutlinedIcon />,
              path: "/help",
            },
            {
              text: "Terms & conditions",
              icon: <DescriptionOutlinedIcon />,
              path: "/Terms",
            },
          ]
            .filter((ele) => ele)
            .map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: "block", color: "white", height: "56px", width: "98%" }}
              >
                <ListItemButton
                  className={`list-item-button-settings ${
                    location.pathname.includes(item?.path) ? "active" : "inactive"
                  }`}
                  onClick={() => handleNavigation(item.path)}
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontWeight: location.pathname.includes(item.path) ? "bold" : "normal", // Bold text if selected
                    }
                  }}
                >
  <ListItemIcon
   className="itemicon"
   sx={{
     minWidth: 0,
     mr: open ? 1 : "auto",
     justifyContent: "center",
     paddingTop: `${item.text === "Home" ? "8px" : ""}`,
   }}
   >
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="MuiSvgIcon-root"
    >
      <defs>
        <linearGradient id="gradientBorderSettings" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#8F3A98" /> {/* Purple */}
          <stop offset="100%" stopColor="#8F3A98" /> {/* Red */}
        </linearGradient>
        <linearGradient id="gradientBorderSettingsInactive" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#625B71" /> {/* Purple */}
          <stop offset="100%" stopColor="#625B71" /> {/* Red */}
        </linearGradient>
      </defs>
      <path
        d="M2.96619 17.2042L3.56938 16.766L2.96619 17.2042C3.19749 17.5225 3.47745 17.8025 3.79581 18.0338C4.33926 18.4287 4.96472 18.5955 5.68957 18.674C6.39111 18.75 7.26856 18.75 8.35673 18.75H8.4H13.6245C13.6655 18.75 13.7058 18.75 13.7457 18.75C14.6669 18.7504 15.3058 18.7507 15.9009 18.5458C16.097 18.4783 16.2871 18.3947 16.4693 18.2958C17.0225 17.9955 17.4539 17.5244 18.0762 16.845C18.1031 16.8156 18.1304 16.7859 18.158 16.7557L20.1017 14.6353L20.1274 14.6073C20.5151 14.1844 20.8447 13.8249 21.0828 13.5037C21.3348 13.1638 21.5336 12.8042 21.5985 12.3696C21.6352 12.1246 21.6352 11.8754 21.5985 11.6304C21.5336 11.1958 21.3348 10.8362 21.0828 10.4963C20.8447 10.1751 20.5151 9.81563 20.1274 9.39273L20.1017 9.36467L18.158 7.24429C18.1304 7.21413 18.1031 7.18435 18.0762 7.15497C17.4539 6.4756 17.0225 6.00452 16.4693 5.70424C16.2871 5.60533 16.097 5.5217 15.9009 5.45422L15.6568 6.16337L15.9009 5.45422C15.3058 5.24933 14.6669 5.24959 13.7457 5.24997C13.7058 5.24998 13.6655 5.25 13.6245 5.25H8.4L8.35672 5.25C7.26855 5.24999 6.3911 5.24998 5.68957 5.32598C4.96472 5.40451 4.33926 5.57134 3.79581 5.96619C3.47745 6.19748 3.19748 6.47745 2.96619 6.79581C2.57134 7.33926 2.40451 7.96472 2.32598 8.68957C2.24998 9.3911 2.24999 10.2685 2.25 11.3567L2.25 11.4V12.6L2.25 12.6433C2.24999 13.7315 2.24998 14.6089 2.32598 15.3104C2.40451 16.0353 2.57134 16.6607 2.96619 17.2042Z"
        stroke="url(#gradientBorderSettings)"
        strokeWidth="2"
        fill="#CAC4D0"
      />
    </svg>
  </ListItemIcon>
  <ListItemText
    primary={item.text}
    sx={{ opacity: open ? 1 : 0 }}
  />
</ListItemButton>
              </ListItem>
            ))}
        </List>

        {/* For sidebar bottom profile */}
        {/* {userDtls?.type !== "influencer" && <Box
          width={"336px"}
          height={"56px"}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          sx={{ cursor: "pointer" }}
          mt={"auto"}
        >
          <LogoutOutlinedIcon color="#79747e" sx={{ ml: "22px" }} />
          <Typography
            display={"flex"}
            className={"textEllipsis mobile-WidthFitContent"}
            // width={"140px"}
            height={20}
            color={"#8F3A98"}
            fontWeight="400"
            fontSize={"15px"}
            variant="body1"
            alignItems={"center"}
            justifyContent={"center"}

          >
            Contact Support
          </Typography>
        </Box>} */}
        <Divider sx={{ background: "white", marginX: "20px", borderWidth: "1px" }} />
         
        {userDtls.type === "company" && (
          <List
          className="component-list"
          sx={{ color: "black.main", fontSize: "14px", marginTop: "70%" }}
        >
          {[
            {
              text: "Contact Support",
              icon: <Mail />,
              path: "/something/"
            }
          ]
            .filter((ele) => ele)
            .map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: "block", color: "white", height: "56px", width: "98%", color:"#8F3A98", position:"relative", bottom:"1" }}
              >
                <ListItemButton
                  className={`list-item-button-settings-contact`}
                  onClick={() => window.location = 'mailto:support@syme.app?subject=Support Request user:'+userDtls.lastName}
                >
  <ListItemIcon
   className="itemicon"
   sx={{
     minWidth: 0,
     mr: open ? 1 : "auto",
     justifyContent: "center",
     paddingTop: `${item.text === "Home" ? "8px" : ""}`,
   }}
   >
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="MuiSvgIcon-root"
    >
      <path
        d="M4 6L10.1076 10.6123L10.1097 10.614C10.7878 11.1113 11.1271 11.3601 11.4988 11.4562C11.8272 11.5412 12.1725 11.5412 12.501 11.4562C12.8729 11.36 13.2132 11.1105 13.8926 10.6123C13.8926 10.6123 17.8101 7.60594 20 6M3 15.8002V8.2002C3 7.08009 3 6.51962 3.21799 6.0918C3.40973 5.71547 3.71547 5.40973 4.0918 5.21799C4.51962 5 5.08009 5 6.2002 5H17.8002C18.9203 5 19.4796 5 19.9074 5.21799C20.2837 5.40973 20.5905 5.71547 20.7822 6.0918C21 6.5192 21 7.07899 21 8.19691V15.8036C21 16.9215 21 17.4805 20.7822 17.9079C20.5905 18.2842 20.2837 18.5905 19.9074 18.7822C19.48 19 18.921 19 17.8031 19H6.19691C5.07899 19 4.5192 19 4.0918 18.7822C3.71547 18.5905 3.40973 18.2842 3.21799 17.9079C3 17.4801 3 16.9203 3 15.8002Z"
        stroke="url(#gradientBorderSettings)"
        strokeWidth="2"
        fill="#ffffff"
      />
    </svg>
  </ListItemIcon>
  <ListItemText
    primary={item.text}
    sx={{ opacity: open ? 1 : 0 }}
  />
</ListItemButton>
              </ListItem>
            ))}
        </List>


        ) }

        <Box
          position={"absolute"}
          bottom={"0"}
          width={"336px"}
          height={"56px"}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          onClick={handleLogout}
          sx={{ cursor: "pointer" }}
          mt={`${userDtls?.type !== "influencer" ? '0px' : 'auto'}`}
        >
          <LogoutOutlinedIcon color="#79747e" sx={{ ml: "22px" }} />
          <Typography
            display={"flex"}
            className={"textEllipsis mobile-WidthFitContent"}
            // width={"140px"}
            height={20}
            color={"#4A4459"}
            fontWeight="400"
            fontSize={"15px"}
            variant="body1"
            alignItems={"center"}
            justifyContent={"center"}
          >
            Log out
          </Typography>
        </Box>
      </Drawer>

      {/**
       *RENDER THIS WHEN MOBILE VIEW
       */}
      {!(location.pathname.includes('/asd/') && location.pathname.split('/offers/')[1]) && <div className="mobile-icons">
        {userDtls.type === "influencer" && (
          <IconButton
            onClick={() => handleNavigation("/home")}
            // sx={{ width: "80px" }}
            height={"100%"}
          >
            <Stack
              sx={{
                color: "#625B71",
                display: "flex",
                alignItems: "center",
                height: "30px",
                fontSize: "12px",
              }}
              className={
                location.pathname.includes("home")
                  ? "activeIcon"
                  : "inactiveIcon"
              }
            >
              <HomeIcon />
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ color: "#1D1B20" }}
              >
                Home
              </Typography>
            </Stack>
          </IconButton>
        )}
        {userDtls.type === "company" && (
          <IconButton
            onClick={() => handleNavigation("/bookings")}
          // sx={{ width: "80px" }}
          >
            <Stack
              sx={{
                color: "#625B71",
                display: "flex",
                alignItems: "center",
                marginTop: "15px",
                height: "50px",
                fontSize: "12px",
              }}
              className={
                location.pathname.includes("bookings")
                  ? "activeIcon"
                  : "inactiveIcon"
              }
            >
              <svg
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <linearGradient id="gradientBorderMobileActive" x1="0%" y1="0%" x2="100%" y2="100%">
                          <stop offset="0%" stopColor="#8F3A98" /> {/* Purple */}
                          <stop offset="100%" stopColor="#8F3A98" /> {/* Red */}
                        </linearGradient>
                        <linearGradient id="gradientBorderMobileInactive" x1="0%" y1="0%" x2="100%" y2="100%">
                          <stop offset="0%" stopColor="#625B71" /> {/* Purple */}
                          <stop offset="100%" stopColor="#625B71" /> {/* Red */}
                        </linearGradient>
                      </defs>
              <BookingIcon
              sx={{
                fill: "url(#gradientBorderMobile)",
                fontSize: "inherit",
              }}
              />
              </svg>
              <Typography
                fontSize={14}
                fontWeight={location.pathname.includes("bookings") ? 700 : 400}
                sx={{ color: "#1D1B20" }}
              >
                Bookings
              </Typography>
            </Stack>
          </IconButton>
        )}
        <IconButton
          onClick={() => handleNavigation("/offers")}
          height={"100%"}
        // sx={{ width: "80px" }}
        >
          <Stack
            sx={{
              color: "#625B71",
              display: "flex",
              alignItems: "center",
              height: "60px",
              fontSize: "12px",
              marginTop: "25px"
            }}
            className={
              location.pathname.includes("offers")
                ? "activeIcon"
                : "inactiveIcon"
            }
          >
            <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="gradientBorderMobileActive" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#8F3A98" /> {/* Purple */}
                <stop offset="100%" stopColor="#8F3A98" /> {/* Red */}
              </linearGradient>
              <linearGradient id="gradientBorderMobileInactive" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#625B71" /> {/* Purple */}
                <stop offset="100%" stopColor="#625B71" /> {/* Red */}
              </linearGradient>
            </defs>
            <OfferIcon color="#1D1B20" 
              sx={{
                fill: "url(#gradientBorderMobile)",
                fontSize: "inherit",
              }}/>
            </svg>
            <Typography
              fontSize={14}
              fontWeight={location.pathname.includes("offers") ? 700 : 400}
              sx={{ color: "#1D1B20", mb: 1 }}
            >
              Offers
            </Typography>
          </Stack>
        </IconButton>
        {userDtls.type !== "company" && (
          <IconButton
            onClick={() => handleNavigation("/bookings")}
          // sx={{ width: "80px" }}
          >
            <Stack
              sx={{
                color: "#625B71",
                display: "flex",
                alignItems: "center",
                height: "30px",
                fontSize: "12px",
              }}
              className={
                location.pathname.includes("bookings")
                  ? "activeIcon"
                  : "inactiveIcon"
              }
            >
              <BookingIcon />
              <Typography
                fontSize={14}
                fontWeight={location.pathname.includes("bookings") ? 700 : 400}
                sx={{ color: "#1D1B20" }}
              >
                Bookings
              </Typography>
            </Stack>
          </IconButton>
        )}
        <IconButton
          onClick={() => handleNavigation("/message")}
        // sx={{ width: "80px" }}
        >
          <Stack
            sx={{
              color: "#625B71",
              display: "flex",
              alignItems: "center",
              height: "50px",
              fontSize: "12px",
              marginTop: "20px"
            }}
            className={
              location.pathname.includes("message")
                ? "activeIcon"
                : "inactive"
            }
          >
            {/* {messageCount && (
              <Badge
                sx={{ marginLeft: 2 }}
                badgeContent={messageCount}
                color="warning"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              ></Badge>
            )} */}
            <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="gradientBorderMobileActive" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#8F3A98" /> {/* Purple */}
                <stop offset="100%" stopColor="#8F3A98" /> {/* Red */}
              </linearGradient>
              <linearGradient id="gradientBorderMobileInactive" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#625B71" /> {/* Purple */}
                <stop offset="100%" stopColor="#625B71" /> {/* Red */}
              </linearGradient>
            </defs>
            <MessageIcon color="#1D1B20" 
              sx={{
                fill: "url(#gradientBorderMobile)",
                fontSize: "inherit",
              }}/>
            </svg>
            <Typography
              fontSize={14}
              fontWeight={location.pathname.includes("message") ? 700 : 400}
              sx={{ color: "#1D1B20" }}
            >
              Messages
            </Typography>
          </Stack>
        </IconButton>
        <IconButton
          onClick={() => handleNavigation("/account")}
        // sx={{ width: "80px" }}
        >
          <Stack
            sx={{
              // color: "#ffffff",
              display: "flex",
              alignItems: "center",
              height: "31px",
              fontSize: "12px",
              marginBottom: "5px"
              }}
            className={
              location.pathname.includes("account")
                ? "activeIcon"
                : "inactiveIcon"
            }
          >
            <img
              alt="loading"
              src={symeLogo}
              style={{ width: 31, height: 31 }}
            ></img>
            <Typography
              fontSize={14}
              fontWeight={location.pathname.includes("account") ? 700 : 400}
              sx={{ color: "#1D1B20" }}
            >
              Account
            </Typography>
          </Stack>
        </IconButton>
      </div>}
    </Box>
  );
};

export default Sidebar;
