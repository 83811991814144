import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogTitle,
  InputBase,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFetchUserListMutation } from "../../../services/apiService/adminApiService";
import useLoading from "../../../hooks/useLoading";
import toaster from "../../../components/Toaster/toaster";
import SimpleTable from "../../../components/simpleTable/SimpleTable";
import { IconButton } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import {
  useCreateChatMutation,
  useGetAllOfferMutation,
  useGetHomeOfferMutation,
  useUpdateHomeOfferMutation,
  useUpdateUserMutation,
} from "../../../services/apiService/userApiService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MyButton from "../../../components/MyButton";
const Users = () => {
  const { userDtls } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [createChat] = useCreateChatMutation();
  const [getAllOffer] = useGetAllOfferMutation();
  const [getHomeViewOffer] = useGetHomeOfferMutation();
  const [updateHomeOffer] = useUpdateHomeOfferMutation();
  const [offerList, setOfferList] = useState([]);
  const [homeOfferId, setHomeOfferId] = useState();
  const [tabName, setTabName] = useState("all");
  const [fetchUserList] = useFetchUserListMutation();
  const [updateUserInfo] = useUpdateUserMutation();
  const { startLoading, stopLoading } = useLoading(); //To set loading
  const [userList, setUserList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const homeViewInitial = { label: "", description: "", offerList: [] };
  const [homeViewDtls, setHomeViewDtls] = useState([homeViewInitial]);

  /**
   * To fetch all offer
   */
  const fetchAllOffer = async () => {
    try {
      startLoading();
      const response = await getAllOffer().unwrap();
      if (response?.data) {
        setOfferList(response?.data);
      } else {
        setOfferList([]);
      }
    } catch (err) {
      if (err?.data?.message) {
        setUserList([]);
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to fetch offer list", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  const fetchHomeOffer = async () => {
    try {
      startLoading();
      const response = await getHomeViewOffer({}).unwrap();
      if (response?.data) {
        setHomeOfferId(response?.data[0]?._id);
        const formatDate = response?.data?.map((ele) => {
          return { ...ele?.details };
        });
        setHomeViewDtls(formatDate);
      } else {
        setHomeViewDtls([]);
        setHomeOfferId("");
      }
    } catch (err) {
      if (err?.data?.message) {
        setUserList([]);
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to fetch offer list", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To update the home offer
   */
  const handlerUpdateHomeOffer = async () => {
    try {
      startLoading();
      const payload = homeViewDtls?.map((ele) => {
        if (ele?.offerList?.length) {
          ele.offerList = ele?.offerList?.map((item) => item?._id);
        }
        return ele;
      });

      const response = await updateHomeOffer({
        details: payload,
        _id: homeOfferId,
      }).unwrap();
      if (response?.data) {
        toaster("info", "Home page view offer updated successfully");
      } else {
        toaster("error", "Home page view offer updated failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        setUserList([]);
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to update the home offer", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
      handlerCloseDialog();
    }
  };
  /**
   * To approve the user
   */
  const handlerApproveUser = async (userId) => {
    try {
      startLoading();
      const response = await updateUserInfo({
        userId,
        toBeUpdated: { isApproved: true },
      }).unwrap();
      if (response?._id) {
        getUserList(tabName);
      } else {
        console.error("error", "Approve user failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        setUserList([]);
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to fetch user list", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To fetch the user list based on the approved type
   */
  const getUserList = async (tabName) => {
    try {
      startLoading();
      let payload;
      if (tabName === "request") {
        payload = false;
      } else if (tabName === "users") {
        payload = true;
      } else {
        payload = "all";
      }
      const response = await fetchUserList(payload).unwrap();
      if (response?.data) {
        setUserList(response?.data);
      } else {
        setUserList([]);
      }
    } catch (err) {
      if (err?.data?.message) {
        setUserList([]);
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to fetch user list", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle the chat navigation
   */
  const handlerChat = async (data) => {
    try {
      startLoading();
      const payload = {
        isGroupChat: false,
        id: userDtls?.userId,
        userId: data?.userId,
      };
      const response = await createChat(payload).unwrap();
      if (response?.data[0]?._id || response?.data?._id) {
        navigate(`/chat/${response?.data[0]?._id || response?.data?._id}`);
      } else {
        toaster("error", "Chat creation failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To set the tab name
   */
  const handlerSetTabName = (e, tabName) => {
    setTabName(tabName);
  };
  /**
   * To open the dialog
   */
  const handlerOpenDialog = () => {
    fetchAllOffer();
    fetchHomeOffer();
    setOpenDialog(true);
  };
  /**
   * To close the dialog
   */
  const handlerCloseDialog = () => {
    setOpenDialog(false);
    setHomeOfferId("");
    setHomeViewDtls([homeViewInitial]);
  };
  /**
   * To handle the field change
   */
  const handleChangeFields = (key, value, idx) => {
    setHomeViewDtls((prev) => {
      const duplicate = [...prev];
      if (key === "offerList" && value?.length > 3) {
        value = value?.splice(0, 1);
      }
      duplicate[idx][key] = value;
      return duplicate;
    });
  };
  /**
   * To check the create home offer is disabled
   */
  const isCreateHomeOfferDisabled = () => {
    let isDisabled = true;
    homeViewDtls?.map((ele) => {
      isDisabled = true;
      if (ele?.label && ele?.description && ele?.offerList?.length > 0) {
        isDisabled = false;
      }
      return ele;
    });
    return isDisabled;
  };
  /**
   * To set column
   */
  const columns = [
    {
      width: 100,
      label: "User Name",
      dataKey: "username",
    },
    {
      width: 100,
      label: "Email",
      dataKey: "email",
    },
    {
      width: 100,
      label: "Type",
      dataKey: "type",
    },
    {
      width: 100,
      label: "Approved",
      dataKey: "isApproved",
      cell: (tableData) => {
        return tableData["isApproved"] ? (
          "User"
        ) : (
          <MyButton onClick={() => handlerApproveUser(tableData?.userId)}>
            Accept
          </MyButton>
        );
      },
    },
    {
      width: 50,
      label: "",
      dataKey: "chat",
      cell: (column) => {
        return (
          <IconButton
            sx={{ height: "32px" }}
            color="primary"
            onClick={() => {
              handlerChat(column);
            }}
          >
            <ChatIcon />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    if (tabName) {
      getUserList(tabName);
    }
  }, [tabName]);
  return (
    <>
      <TabContext value={tabName} sx={{ padding: "5px", width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handlerSetTabName}
            aria-label="lab API tabs example"
          >
            <Tab
              sx={{ textTransform: "capitalize" }}
              fontWeight={700}
              label="All"
              value="all"
              color="white"
            />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Request"
              value="request"
            />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Users"
              value="users"
            />
            <Button
              onClick={() => handlerOpenDialog()}
              sx={{
                ml: "auto",
                mr: 1,
                mt: 1,
                color: "#fff",
                bgcolor: "#8F3A98",
                height: 30,
                "&:hover": {
                  backgroundColor: "#8F3A98",
                },
                textTransform: "none",
              }}
            >
              Home view
            </Button>
          </TabList>
        </Box>
        <Box
          width={"100%"}
          height={"calc(100% - 49px)"}
          padding={1}
          boxShadow={3}
        >
          <SimpleTable
            tableData={userList}
            columns={columns}
            handlerApproveUser={handlerApproveUser}
          ></SimpleTable>
        </Box>
      </TabContext>
      <Dialog
        // fullScreen={fullScreen}
        open={openDialog}
        onClose={handlerCloseDialog}
        aria-labelledby="responsive-dialog-title"
        sx={{
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          bgcolor: "#fff",
          backgroundColor: "#fff",
          boxShadow: "none",
          height: "100%",
          overflow: "auto",
          p: 1,
        }}
        className="modalSection"
      >
        <Box
          // className="acceptOfferModalCtn"
          sx={{
            p: 3,
            minWidth: 320,
            width: 340,
            maxWidth: 450,
            bgcolor: "#fff",
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            textAlign={"center"}
            fontSize={24}
            fontWeight={400}
          >
            Home view
          </DialogTitle>
          <Box sx={{ p: 1 }}>
            {homeViewDtls?.map((ele, idx) => {
              return (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={1}
                  p={2}
                  boxShadow={3}
                  borderRadius={"20px"}
                  mb={2}
                >
                  <InputBase
                    onChange={(e) =>
                      handleChangeFields("label", e?.target?.value, idx)
                    }
                    // onBlur={handleOnBlur}
                    defaultValue={ele?.label}
                    value={ele?.label}
                    name="label"
                    type={"text"}
                    placeholder="Label"
                    required
                    sx={{
                      flex: 1,
                      bgcolor: "#fff",
                      p: 1,
                      borderRadius: "5px",
                    }}
                  />
                  <InputBase
                    onChange={(e) =>
                      handleChangeFields("description", e?.target?.value, idx)
                    }
                    // onBlur={handleOnBlur}
                    value={ele?.description}
                    defaultValue={ele?.description}
                    name="description"
                    type={"text"}
                    placeholder="description"
                    required
                    sx={{
                      flex: 1,
                      bgcolor: "#fff",
                      p: 1,
                      borderRadius: "5px",
                    }}
                  />
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={offerList}
                    getOptionLabel={(option, value) => option?.title}
                    onChange={(e, value) => {
                      handleChangeFields("offerList", value, idx);
                    }}
                    sx={{ bgcolor: "#fff", p: 1, borderRadius: "5px" }}
                    defaultValue={[...ele?.offerList]}
                    // value={ele?.offerList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Offers"
                        placeholder="Select offers..."
                      />
                    )}
                  />
                  {homeViewDtls?.length > 1 && (
                    <Button
                      onClick={() => {
                        setHomeViewDtls((prev) => {
                          const dup = [...prev];
                          dup?.splice(idx, 1);
                          return dup;
                        });
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              );
            })}
          </Box>
          {homeViewDtls?.length < 3 && (
            <Button
              onClick={() =>
                setHomeViewDtls([...homeViewDtls, homeViewInitial])
              }
            >
              Add
            </Button>
          )}
          <Stack
            direction="row"
            spacing={2}
            mt={3}
            justifyContent="space-between"
          >
            <MyButton
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
                bgcolor: "#FFF",
                color: "#8F3A98",
              }}
              onClick={() => {
                handlerCloseDialog();
              }}
            >
              cancel
            </MyButton>

            <Button
              variant="outlined"
              onClick={() => {
                handlerUpdateHomeOffer();
              }}
              disabled={isCreateHomeOfferDisabled()}
              sx={{
                color: "#fff",
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
                bgcolor: "#8F3A98",
              }}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default Users;
